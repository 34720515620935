import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PopperTooltip from '@eva/emf/app/shared/ui/Popper/PopperTooltip';
import { requestBackend } from '@eva/emf/app/utils/request';
import { processValidationErrors, stringifyError } from 'shared/functions';
import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';

import FormLanguage from './FormLanguage';
import FormNote from './FormNote';
import { eventTypes } from './constants';
import { updateLanguages } from './functions';

const newItemKey = 'language-new';

class CardLanguages extends React.PureComponent<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    editModeRows: {},
    deleteModeRows: {},
    rowsErrors: {},
    note: '',
    notePreviewMode: true,
  };
  static contextTypes: { isAllowedOperation: PropTypes.Validator<(...args: any[]) => any> };
  static propTypes: {
    entity: PropTypes.Validator<object>;
    editOptions: PropTypes.Validator<object>;
    updateEntity: PropTypes.Validator<(...args: any[]) => any>;
    open: PropTypes.Requireable<boolean>;
  };

  UNSAFE_componentWillMount() {
    const { entity, open } = this.props;

    if (entity?.languages.note) {
      this.setState({
        note: entity.languages.note,
      });
    }

    // @ts-expect-error
    this.loadLanguages(entity.languages, open);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity } = nextProps;
    if (this.props.entity.languages !== entity.languages) {
      this.loadLanguages(entity.languages);
    }
  }

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  setItemEditMode = (languageId, editMode) => {
    const { editModeRows } = this.state;
    if (languageId === null) {
      return this.setState({
        newLanguageItem: null,
      });
    }
    this.setState({
      editModeRows: {
        ...editModeRows,
        [languageId]: editMode,
      },
    });
  };

  loadLanguages(languages) {
    this.setState({
      languages,
    });
  }

  saveLanguageItem = async (preValues) => {
    const { updateEntity } = this.props;

    const values = preValues.toJS();
    const { candidateLanguageId, languageId, languageProficiency } = values;

    this.setState({
      savingProfile: true,
    });
    return await requestBackend(`/my/candidate-profile/languages/${candidateLanguageId || ''}`, {
      method: candidateLanguageId ? 'PUT' : 'POST',
      body: JSON.stringify({
        languageId,
        languageProficiencyId:
          languageProficiency && typeof languageProficiency === 'object'
            ? languageProficiency.languageProficiencyId
            : languageProficiency,
      }),
    }).then(
      (response) => {
        // @ts-expect-error
        if (this.unmounted) {
          return;
        }
        const {
          entity: { languages },
        } = this.props;
        this.setItemEditMode(candidateLanguageId || null, false);
        const eventType = candidateLanguageId ? eventTypes.languageUpdated : eventTypes.languageCreated;
        updateEntity({
          languages: updateLanguages(languages, eventType, response),
        });
      },
      (err) => processValidationErrors(values)(err),
    );
  };

  deleteLanguageItem(candidateLanguageId) {
    const { updateEntity } = this.props;
    const { deleteModeRows, rowsErrors } = this.state;

    this.setState({
      deleteModeRows: {
        ...deleteModeRows,
        [candidateLanguageId]: true,
      },
      rowsErrors: {
        ...rowsErrors,
        [candidateLanguageId]: '',
      },
    });

    requestBackend(`/my/candidate-profile/languages/${candidateLanguageId}`, {
      method: 'DELETE',
    }).then(
      () => {
        const {
          entity: { languages },
        } = this.props;
        updateEntity({
          languages: updateLanguages(languages, eventTypes.languageDeleted, {
            candidateLanguageId,
          }),
        });
      },
      (err) => {
        // @ts-expect-error
        if (this.unmounted) {
          return;
        }

        this.setState({
          deleteModeRows: {
            ...deleteModeRows,
            [candidateLanguageId]: false,
          },
          rowsErrors: {
            ...rowsErrors,
            [candidateLanguageId]: stringifyError(err),
          },
        });
      },
    );
  }

  handleToggleNotePreviewMode() {
    const { notePreviewMode } = this.state;

    this.setState({
      notePreviewMode: !notePreviewMode,
    });
  }

  getNotePatch(newNoteValue) {
    const {
      entity: { languages },
    } = this.props;

    return {
      languages: {
        languageNote: newNoteValue,
        items: languages.items,
      },
    };
  }

  renderNewItem = () => {
    const { editOptions } = this.props;
    const { newLanguageItem } = this.state as any;

    return newLanguageItem ? (
      <div key={newItemKey}>
        <div>
          <FormLanguage
            form={newItemKey}
            initialValues={newLanguageItem}
            // @ts-expect-error
            editOptions={editOptions}
            onSubmit={this.saveLanguageItem}
            setItemEditMode={this.setItemEditMode}
          />
        </div>
      </div>
    ) : null;
  };

  renderItem = (language, index) => {
    const { isAllowedOperation } = this.context;
    const { editOptions } = this.props;
    const { editModeRows, deleteModeRows, rowsErrors } = this.state;

    if (editModeRows[language.candidateLanguageId]) {
      const key = `language-${language.candidateLanguageId}`;
      return (
        <div key={key}>
          <div>
            <FormLanguage
              form={key}
              initialValues={language}
              // @ts-expect-error
              editOptions={editOptions}
              onSubmit={this.saveLanguageItem}
              setItemEditMode={this.setItemEditMode}
            />
          </div>
        </div>
      );
    }

    return (
      <div key={`languages-${index}`} className="language-item">
        <div className="row">
          {rowsErrors[language.candidateLanguageId] && (
            <div className="text-danger">{rowsErrors[language.candidateLanguageId]}</div>
          )}
          <div className="col-9">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label className="control-label">{translate('Language')}</label>
                  <div>
                    <i
                      className={`flag-icon-background flag-icon flag-icon-${language.name.split(' ')[0]} flag-icon-${
                        language.code
                      }`}
                    />{' '}
                    {language.name}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label className="control-label">{translate('Language proficiency')}</label>
                  <div>{language.languageProficiency && `${language.languageProficiency.name}`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-nowrap text-end">
            <div>
              {isAllowedOperation('myProfile-languages-update') && (
                <PopperTooltip overlay={translate('Edit')}>
                  <button
                    id="edit-languages"
                    type="button"
                    className="btn-box-tool btn btn-sm pencil-edit-btn no-padding"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      this.setItemEditMode(language.candidateLanguageId, true);
                    }}
                  >
                    <i className="lnr lnr-pencil" />
                  </button>
                </PopperTooltip>
              )}
              {isAllowedOperation('myProfile-languages-delete') && (
                <PopperTooltip overlay={translate('Delete')}>
                  <button
                    type="button"
                    className="btn-box-tool btn btn-sm margin-left no-padding"
                    onClick={() => this.deleteLanguageItem(language.candidateLanguageId)}
                  >
                    {deleteModeRows[language.candidateLanguageId] && <SpinnerSmall />}
                    {!deleteModeRows[language.candidateLanguageId] && <i className="lnr lnr-trash text-danger" />}
                  </button>
                </PopperTooltip>
              )}
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    );
  };

  render() {
    const {
      entity: { languages },
      updateEntity,
    } = this.props;
    const { outdated, saving, error, notePreviewMode } = this.state as any;

    const { note } = languages;

    if (saving) {
      return (
        <div>
          <p>{translate('Saving languages')}...</p>
        </div>
      );
    }

    const header = (
      <h3 onClick={(evt) => evt.stopPropagation()} className="text-primary">
        {translate('Languages')}
        <span className="pull-right">
          {notePreviewMode && (
            <button
              type="button"
              id="add-language-note"
              onClick={(evt) => {
                evt.stopPropagation();
                this.handleToggleNotePreviewMode();
              }}
            >
              <span className="btn-box-tool btn-sm no-padding pull-right check-save-btn margin-right check-save-btn">
                <i className="fa fa-sticky-note-o" />
              </span>
            </button>
          )}
          <PopperTooltip overlay={translate('Add')}>
            <button
              type="submit"
              id="add-language-item"
              className="btn btn-box-tool btn-sm no-padding text-success check-save-btn"
              onClick={(evt) => {
                evt.stopPropagation();
                this.setState({
                  newLanguageItem: {},
                });
              }}
            >
              <i className="lnr lnr-plus-circle text-success" />
            </button>
          </PopperTooltip>
        </span>
      </h3>
    );

    return (
      <div id="languages-panel" className={`section ${outdated ? 'not-approved' : ''}`}>
        {error && <p className="text-danger">{error}</p>}
        {header}

        <FormNote
          defaultNote={note}
          previewMode={notePreviewMode}
          getBody={(val) => this.getNotePatch(val)}
          onFinishEdit={() => this.handleToggleNotePreviewMode()}
          onUpdateEntity={updateEntity}
        />

        {this.renderNewItem()}
        {!languages.items.length && <p>{translate('No languages saved')}</p>}
        {languages.items.map(this.renderItem)}
      </div>
    );
  }
}

CardLanguages.contextTypes = {
  isAllowedOperation: PropTypes.func.isRequired,
};

CardLanguages.propTypes = {
  entity: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

// eslint-disable-next-line import/no-default-export
export default connect()(CardLanguages);
