import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { Field } from 'redux-form/immutable';

import { getYearsOptions } from 'shared/functions';
import { RenderSelect } from '@eva/emf/app/shared/ui/RenderSelect';

import { getMonthsOptions } from '../../../shared/functions';

const yearsOptions = getYearsOptions(90, 0).sort((m1, m2) => m2.value - m1.value);

const monthsOptions = getMonthsOptions();

const CalendarSelector = ({ input: { name }, setTouched }) => {
  const handleChange = useCallback(() => {
    if (setTouched) {
      setTouched(true);
    }
  }, [setTouched]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <Field
          component={RenderSelect}
          valueKey="value"
          name={`${name}.month`}
          options={monthsOptions}
          onChange={handleChange}
        />
      </div>
      <div className="col-12 col-md-6">
        <Field
          component={RenderSelect}
          valueKey="value"
          name={`${name}.year`}
          options={yearsOptions}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

CalendarSelector.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.object,
  setTouched: PropTypes.func.isRequired,
  touched: PropTypes.bool.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default memo(CalendarSelector);
