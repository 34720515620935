import { useEffect, useMemo, useCallback, useState } from 'react';
import trim from 'lodash/trim';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import { StaticPageScripts } from '@eva/emf/app/shared/utils';
import { useGenericContext } from 'shared/hooks';
import type { StaticPageDto } from '@eva/types/dto';
import { setQueryVariables } from 'shared/functions';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';

import type { ReactNode } from 'react';

import { setPiToken, getStaticPageContent, getStaticPageIdFromUrl } from './functions';

export interface StaticPagesWidgetProps {
  mobileMode?: boolean;
  setCentralContent: (node?: ReactNode) => void;
  staticPages: StaticPageDto[];
}

const StaticPagesWidget = ({ mobileMode, setCentralContent, staticPages }: StaticPagesWidgetProps) => {
  const { isAllowedOperation, getUserProfile } = useGenericContext();

  const sortedStaticPages = useMemo(() => sortBy(staticPages || [], (item: any) => trim(item.name)), [staticPages]);

  const defaultPageId = mobileMode ? undefined : sortedStaticPages?.[0]?.staticPageId;

  const [activeStaticPageId, setActiveStaticPageId] = useState(
    getStaticPageIdFromUrl(sortedStaticPages, defaultPageId),
  );

  const user = getUserProfile();

  const onSelect = useCallback(
    async (staticPage: any) => {
      setActiveStaticPageId(staticPage?.staticPageId);
      setCentralContent(
        <div className="text-center">
          <Spinner />
        </div>,
      );
      setQueryVariables({
        staticPageId: staticPage?.staticPageId,
      });

      const content = getStaticPageContent(staticPage, { userId: user.userId });

      await setPiToken({ content, isAllowedOperation });

      setCentralContent(
        <div className="job-central-panel">
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <StaticPageScripts />
        </div>,
      );
    },
    [user, isAllowedOperation, setCentralContent, setActiveStaticPageId],
  );

  useEffect(() => {
    const defaultPage = mobileMode ? undefined : sortedStaticPages?.[0];

    const selectedPage = activeStaticPageId
      ? sortedStaticPages.find(({ staticPageId }) => staticPageId === activeStaticPageId)
      : defaultPage;

    if (!selectedPage) {
      return;
    }

    onSelect(selectedPage);
  }, [mobileMode, activeStaticPageId, sortedStaticPages, onSelect]);

  if (isEmpty(sortedStaticPages)) {
    return <div />;
  }

  return (
    <div className="padding text-start static-pages-wrapper">
      <hr className="dotted" />

      <label className="control-label">MY SPACES</label>

      <ul>
        {sortedStaticPages.map((staticPage: any, index: number) => (
          <li
            className={`margin-min-vertical text-start ${
              staticPage.staticPageId === activeStaticPageId ? 'active' : ''
            }`}
            onClick={() => onSelect(staticPage)}
            key={index}
          >
            <i className="lnr lnr-rocket margin-right" />
            <span>{staticPage.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default StaticPagesWidget;
