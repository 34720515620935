import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import { Spinner } from '@eva/emf/app/shared/ui/Spinner';

import JobDetails from 'containers/JobDetails';
import noCompanyImage from 'assets/images/no-company.png';

import { renderStep } from '../functions';
import { statusesSteps, endedStatusesSteps } from '../constants';

class SelectJob extends React.Component<any, any> {
  selectPipeline(pipeline) {
    const { setCentralContent, emitSendMessage, switchToChat } = this.props;
    this.setState({ pipeline });
    setCentralContent(
      <div className="job-central-panel">
        <JobDetails jobCode={pipeline.job.code} emitSendMessage={emitSendMessage} closeJobDetails={switchToChat} />
      </div>,
    );
  }

  renderSteps(pipeline) {
    const candidateLabel = pipeline.workflowProcess?.workflowState?.candidateLabel;

    let statusesList;
    if (statusesSteps[candidateLabel]) {
      statusesList = statusesSteps;
    } else if (endedStatusesSteps[candidateLabel]) {
      statusesList = endedStatusesSteps;
    }
    if (statusesList) {
      // @ts-expect-error
      return [1, 2, 3].map((step) => renderStep(statusesList === statusesSteps, statusesList, step, pipeline));
    }
    return null;
  }

  renderPipeline = (pipeline) => {
    const { emitSendMessage, switchToChat } = this.props;

    const jobAvatarUrl = get(pipeline, 'job.avatar.url') || get(pipeline, 'job.company.avatar.url');

    const candidateLabel = pipeline.workflowProcess?.workflowState?.candidateLabel;

    return (
      <div
        key={pipeline.pipelineId}
        onClick={() => {
          // eslint-disable-next-line no-useless-concat
          emitSendMessage(`/postjob ${pipeline.job.code}#` + `${translate('I would like to discuss this job')}:`);
          switchToChat();
        }}
      >
        <div className="job-pipeline job-item media">
          <div className="media-left">
            <div className="company-img-crop">
              <img className="media-object" src={jobAvatarUrl || noCompanyImage} alt="" />
            </div>
          </div>
          <div className="media-body">
            <div className="text-start">
              <i
                className="ll-icon ll-info fa-2x pull-right text-dark-blue"
                style={{
                  fontSize: '18px',
                }}
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  this.selectPipeline(pipeline);
                }}
              />
              <h3>{pipeline.job.title || '(No title)'}</h3>
              <div className="job-company margin-top">{get(pipeline, 'job.company.name')}</div>
            </div>
          </div>
          <div className="row margin-top hide">
            <div className="col-4">
              <span className="label label-default">{(candidateLabel || '').replace(/[^a-zA-Z]+/g, '')}</span>
            </div>
            <div className="col-8">
              <div className="progress-line">{this.renderSteps(pipeline)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { hideJobs, pipelines, loadingPipelines } = this.props;

    return (
      <div className="text-center">
        <div className="text-start">
          <p className="margin-top margin-bottom">
            <i
              className="lnr lnr-arrow-left"
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                verticalAlign: 'text-bottom',
                marginLeft: '20px',
              }}
            />
            <a onClick={() => hideJobs()} className="control-label margin-left">
              {translate('Back to topics')}
            </a>
          </p>
        </div>
        {loadingPipelines && <Spinner />}
        {!loadingPipelines && (
          <div>
            {!pipelines.length && <h3>{translate("You don't have jobs yet!")}</h3>}
            {!!pipelines.length && (
              <div className="select-job">
                <div className="col-sm-10 col-sm-offset-1">
                  <h1>{translate('Select Job')}</h1>
                  <div className="intro margin-bottom">
                    {translate('Select the job you would like to discuss below')}
                  </div>
                </div>
                <div className="clear" />
                {pipelines.map(this.renderPipeline)}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

// @ts-expect-error
SelectJob.propTypes = {
  hideJobs: PropTypes.func.isRequired,
  emitSendMessage: PropTypes.func.isRequired,
  switchToChat: PropTypes.func.isRequired,
  setCentralContent: PropTypes.func.isRequired,
  pipelines: PropTypes.array.isRequired,
  loadingPipelines: PropTypes.bool.isRequired,
};
// eslint-disable-next-line import/no-default-export
export default SelectJob;
