/* eslint-disable no-restricted-properties */
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';

import { UserDropdown } from '@eva/emf/app/shared/ui/UserDropdown';
import { localStorageService } from '@eva/emf/app/services/localStorage';
import { leftMenuModes } from 'shared/constants';
import { clearQueryVariables, getUserDropdownLinks, temporaryUser } from 'shared/functions';

import { restartWorkflowCodes } from 'containers/MessengerLeftMenu/constants';

const MessengerMobileHeader = (props: any) => {
  const { user, userProfile, userDetails, title, toggleLeft, centralContent, dispatchSignOut } = props;

  const candidateFlowStateCode = useMemo(() => get(userDetails, 'rw.workflow_state.code'), [userDetails]);

  const isRestartWorkflow = useMemo(
    () => restartWorkflowCodes.includes(candidateFlowStateCode),
    [candidateFlowStateCode],
  );

  const shouldShowSignIn = useMemo(() => !user.userId || isRestartWorkflow, [user.userId, isRestartWorkflow]);

  const label = !user.userId || temporaryUser(userDetails) ? translate('Sign in') : '';

  const renderUserDropdown = useCallback(() => {
    const authTypes = localStorageService.authTypes;
    const user = localStorageService.user;

    if (!!authTypes.length && user.userId) {
      return (
        <UserDropdown
          links={getUserDropdownLinks()}
          email={userProfile.emails?.primary?.emailAddress}
          displayName={userProfile.name?.displayName}
          dispatchSignOut={dispatchSignOut}
        />
      );
    }
  }, [dispatchSignOut, userProfile.emails?.primary?.emailAddress, userProfile.name?.displayName]);

  return (
    <div className="mobile-header">
      <div className="col-3">
        <i
          className={`fa fa-2x margin-top fa-${centralContent ? 'chevron-left' : 'bars'}`}
          onClick={() => {
            clearQueryVariables();
            toggleLeft(leftMenuModes.menu);
          }}
        />
      </div>
      <div className="col-6 text-center">
        <span className="title-mobile">{title}</span>
      </div>
      {renderUserDropdown()}
      {shouldShowSignIn && (
        <div className="col-3 text-end">
          <a
            className="margin-top"
            title={label}
            onClick={() => {
              toggleLeft(leftMenuModes.signIn);
            }}
          >
            {label}
          </a>
        </div>
      )}
    </div>
  );
};

MessengerMobileHeader.propTypes = {
  user: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  toggleLeft: PropTypes.func.isRequired,
  centralContent: PropTypes.bool,
  dispatchSignOut: PropTypes.func.isRequired,
  userProfile: PropTypes.any,
};

// eslint-disable-next-line import/no-default-export
export default MessengerMobileHeader;
