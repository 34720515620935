import React, { useCallback, useState } from 'react';

import { removePathsFromError, stringifyError } from '@eva/emf/app/shared/functions';
import { ButtonSpinner } from '@eva/emf/app/shared/ui/Spinner';
import { requestBackend } from '@eva/emf/app/utils/request';

interface ScreeningQuestionProps {
  question: {
    description: string;
    question: string;
    answer: {
      answer: string;
    };
    jobScreeningQuestionId: number;
  };
  isAllowedToAnswer: boolean;
  pipelineId: number;
}

const ScreeningQuestion: React.FC<ScreeningQuestionProps> = ({ question, isAllowedToAnswer, pipelineId }) => {
  const [defaultAnswer, setDefaultAnswer] = useState(question.answer ? question.answer.answer : '');
  const [isShowAnswer, setIsShowAnswer] = useState(false);
  const [answer, setAnswer] = useState(defaultAnswer);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateAnswer = useCallback(
    () =>
      requestBackend(`/my/pipelines/${pipelineId}/questions/${question.jobScreeningQuestionId}/answer`, {
        method: 'PUT',
        body: JSON.stringify({
          answer,
        }),
      }),
    [answer, question.jobScreeningQuestionId, pipelineId],
  );

  const onSave = useCallback(async () => {
    try {
      setIsLoading(true);
      const data: any = await updateAnswer();
      setDefaultAnswer(data.answer);
      setError('');
    } catch (err) {
      const errorWithoutPath = removePathsFromError(err);
      return setError(stringifyError(errorWithoutPath));
    } finally {
      setIsShowAnswer(false);
      setIsLoading(false);
    }
  }, [updateAnswer]);

  const onCancel = useCallback(() => {
    setAnswer(defaultAnswer);
    setIsShowAnswer(false);
    setError('');
  }, [defaultAnswer]);

  const showEditOrButtonsForm = () => {
    if (isShowAnswer) {
      return (
        <div className="pull-right">
          <button
            type="submit"
            className="btn btn-sm btn-box-tool text-success no-padding"
            onClick={onSave}
            disabled={isLoading}
          >
            <i className="lnr lnr-checkmark-circle"></i>
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="btn btn-sm btn-box-tool margin-left no-padding"
            disabled={isLoading}
          >
            <i className="lnr lnr-cross-circle"></i>
          </button>
        </div>
      );
    }
    return (
      <button
        type="button"
        className="btn btn-tool-box pull-right btn-sm no-padding"
        onClick={() => setIsShowAnswer(true)}
      >
        <i className="lnr lnr-pencil" />
      </button>
    );
  };

  const showButtonsForm = () => {
    if (isShowAnswer) {
      return (
        <div className="text-end margin-top">
          <ButtonSpinner
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={onSave}
            text={translate('Save')}
            className="btn btn-success btn-sm margin-right"
            type="submit"
          />
          <button className="btn btn-default btn-sm" type="button" onClick={onCancel} disabled={isLoading}>
            {translate('Cancel')}
          </button>
        </div>
      );
    }
  };
  return (
    <div className="question">
      <p className="question-text margin-min-vertical">{question.question}</p>
      {isShowAnswer && <p className="small text-muted margin-min-vertical">{question.description}</p>}
      <div className="form-group">
        {isAllowedToAnswer && showEditOrButtonsForm()}
        <label className="control-label">{translate('Answer')}</label>
        {isShowAnswer && (
          <textarea
            className="form-control"
            value={answer}
            placeholder={translate('No answer')}
            onChange={(e) => setAnswer(e.target.value)}
            maxLength={4000}
          />
        )}
        {!isShowAnswer && <p style={{ whiteSpace: 'pre-wrap' }}>{answer || `${translate('No answer')}`}</p>}
        {error && <div className="text-danger">{error}</div>}
        {showButtonsForm()}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ScreeningQuestion;
