import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import min from 'lodash/min';
import uniq from 'lodash/uniq';
import Modal from 'react-bootstrap/Modal';

import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import { copy, loadJobInterimAvailability } from 'shared/functions';

import InterimAvailabilityEditor from 'containers/AvailabilityEditor/InterimAvailabilityEditor';

import { dateTypes } from './constants';
import { getJobsFromCandidatePipeline, mapSlotDates, slotByTime } from './functions';

const mergeSlots = (jobInitialSlots, candidateInitialSlots) => {
  const jobSlots = copy(jobInitialSlots);
  const candidateSlots = copy(candidateInitialSlots);
  const slotsTimes = uniq([...jobSlots.flatMap(mapSlotDates), ...candidateSlots.flatMap(mapSlotDates)]).sort(
    (prev, cur) => (prev > cur ? 1 : -1),
  );

  const events = [];
  let currentJobSlot;
  let currentCandidateSlot;
  let anyCurrentSlot;
  slotsTimes.forEach((slotTime) => {
    const startedJobSlot = slotByTime(jobSlots, dateTypes.startAt, slotTime);
    const startedCandidateSlot = slotByTime(candidateSlots, dateTypes.startAt, slotTime);
    const endedJobSlot = slotByTime(jobSlots, dateTypes.endAt, slotTime);
    const endedCandidateSlot = slotByTime(candidateSlots, dateTypes.endAt, slotTime);

    const lastEvent = events[events.length - 1];
    if (lastEvent && lastEvent.endAt > slotTime) {
      events[events.length - 1].endAt = slotTime;
    }
    currentJobSlot = endedJobSlot && !startedJobSlot ? null : startedJobSlot || currentJobSlot;
    currentCandidateSlot =
      endedCandidateSlot && !startedCandidateSlot ? null : startedCandidateSlot || currentCandidateSlot;
    anyCurrentSlot = currentCandidateSlot || currentJobSlot;
    if (anyCurrentSlot) {
      const gap = !!currentJobSlot && !currentCandidateSlot;
      events.push({
        startAt: slotTime,
        endAt: min([get(currentCandidateSlot, 'endAt'), get(currentJobSlot, 'endAt')]),
        availabilityType: gap ? 3 : anyCurrentSlot.availabilityType,
        overlap: !!currentJobSlot && !!currentCandidateSlot,
      });
    }
  });

  return events;
};

// eslint-disable-next-line import/no-default-export
export default class ModalInterimAvailabilityEditor extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    interimAvailability: {},
    interimAvailabilities: {},
    candidateInterimAvailability: {
      slots: [],
    },
    selectedJobs: {},
    settings: {},
    error: '',
    jobs: [],
  };
  unmounted: any;
  resolve: any;
  reject: (reason?: any) => void;
  promise: Promise<unknown>;
  static propTypes: {
    availabilityTypes: PropTypes.Validator<any[]>;
    availabilityLegendBlocks: PropTypes.Validator<any[]>;
    job: PropTypes.Requireable<object>;
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  onEnableJob(jobId, enabled) {
    this.setState(
      {
        selectedJobs: {
          [jobId]: enabled,
        },
      },
      () => this.mergeJobAvailabilities(),
    );
  }

  mergeJobAvailabilities() {
    const { selectedJobs, interimAvailabilities, interimAvailability, candidateInterimAvailability } = this.state;
    const availabilitiesToMerge = Object.entries(interimAvailabilities)
      // @ts-expect-error
      .filter((entry) => selectedJobs[entry[0]] && entry[1] && !entry[1].loading)
      // @ts-expect-error
      .flatMap((entry) => entry[1].slots);
    this.setState({
      interimAvailability: {
        ...interimAvailability,
        slots: availabilitiesToMerge.length
          ? mergeSlots(availabilitiesToMerge, candidateInterimAvailability.slots)
          : candidateInterimAvailability.slots,
      },
    });
  }

  ok = () => {
    const { candidateInterimAvailability, description, changesApplied } = this.state as any;
    const closeAndReturn = () => {
      if (!this.unmounted) {
        this.setState({
          saving: false,
          show: false,
        });
        this.resolve({
          ...candidateInterimAvailability,
          description,
        });
      }
    };
    if (!changesApplied) {
      return closeAndReturn();
    }
    this.setState({
      saving: true,
      error: '',
    });
  };

  cancel = () => {
    this.setState({ show: false });
  };

  loadJobs(jobs) {
    const { settings } = this.state;

    const matchWithJobTime = get(settings, 'features.candidate.workProfile.preferredWorkingHours.matchWithJobTime');
    if (!matchWithJobTime) {
      return;
    }

    this.setState({
      interimAvailabilities: jobs.reduce(
        (prev, cur) => ({
          ...prev,
          [cur.jobId]: {
            loading: true,
          },
        }),
        {},
      ),
    });
    jobs.forEach(({ jobId }) =>
      loadJobInterimAvailability(jobId).then(
        (permanentAvailability) =>
          !this.unmounted &&
          this.setState(
            {
              interimAvailabilities: {
                ...this.state.interimAvailabilities,
                [jobId]: permanentAvailability,
              },
            },
            () => this.mergeJobAvailabilities(),
          ),
      ),
    );
  }

  open(settings, interimAvailability, candidatePipelines = []) {
    const { job } = this.props;

    const jobs = getJobsFromCandidatePipeline(candidatePipelines);

    if (job) {
      jobs.push(job);
    }

    this.loadJobs(jobs);

    this.setState({
      show: true,
      settings,
      interimAvailability,
      candidateInterimAvailability: copy(interimAvailability),
      description: interimAvailability.description,
      jobs,
      selectedJobs: {},
      changesApplied: false,
      error: '',
      saving: false,
    });

    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });

    return this.promise;
  }

  updateAvailability = (slots) => {
    const { candidateInterimAvailability, selectedJobs } = this.state;
    this.setState(
      {
        candidateInterimAvailability: {
          ...candidateInterimAvailability,
          slots,
        },
        changesApplied: true,
      },
      () =>
        Object.values(selectedJobs).some((item) => item)
          ? this.mergeJobAvailabilities()
          : this.setState({ interimAvailability: this.state.candidateInterimAvailability }),
    );
  };

  renderJob = (job) => {
    const { interimAvailabilities, selectedJobs } = this.state;

    const jobLoading = (interimAvailabilities[job.jobId] || {}).loading;
    return (
      <label key={job.jobId}>
        <div
          className="media job-list-item"
          style={{
            padding: '5px 10px',
          }}
        >
          <div className="media-left">
            <input
              type="checkbox"
              disabled={jobLoading}
              checked={!!selectedJobs[job.jobId]}
              onChange={(evt) => this.onEnableJob(job.jobId, evt.target.checked)}
              style={{ margin: 0 }}
            />
          </div>
          <div className="media-body">
            <div>
              <h3 className="job-role">{job.title}</h3>
            </div>
            <div>{job.company.name}</div>
          </div>
          <div className="media-right" style={{ minWidth: '40px' }}>
            {jobLoading && (
              <div>
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </label>
    );
  };

  render() {
    const { availabilityTypes, availabilityLegendBlocks } = this.props;
    const {
      show,
      interimAvailability,
      candidateInterimAvailability: { slots },
      description,
      jobs,
      settings,
      error,
      saving,
    } = this.state as any;

    const matchWithJobTime = get(settings, 'features.candidate.workProfile.preferredWorkingHours.matchWithJobTime');

    return (
      <Modal show={show} className="full-screen-modal" onHide={this.cancel} size="lg">
        <form onSubmit={this.ok}>
          <Modal.Header closeButton>
            <Modal.Title>{translate('Shifts')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="no-padding">
            <div className="availability-block row no-margin">
              <div className="col-9" style={{ paddingLeft: 0 }}>
                <InterimAvailabilityEditor
                  settings={settings}
                  interimAvailability={interimAvailability}
                  initialInterimSlots={slots}
                  updateAvailability={this.updateAvailability}
                  availabilityTypes={availabilityTypes}
                />
              </div>
              <div className="col-3">
                {saving && <Spinner />}
                {error && <div className="alert alert-danger margin-top">{error}</div>}
                <div className="form-group margin-top">
                  <label className="control-label">{translate('Calendar note')}</label>
                  <textarea
                    className="form-control"
                    rows={4}
                    value={description}
                    onChange={(evt) =>
                      this.setState({
                        description: evt.target.value,
                        changesApplied: true,
                      })
                    }
                  />
                </div>
                {matchWithJobTime && !!jobs.length && (
                  <div className="job-list availability-edit-list">{jobs.map(this.renderJob)}</div>
                )}
                <div className="row small margin-top legend-box">
                  <div className="col-5">{availabilityLegendBlocks[0]}</div>
                  {matchWithJobTime && <div className="col-7">{availabilityLegendBlocks[1]}</div>}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" disabled={saving} onClick={this.ok} className="btn btn-sm btn-success">
              {translate('Submit')}
            </button>
            <button type="button" disabled={saving} onClick={this.cancel} className="btn btn-sm btn-default">
              {translate('Cancel')}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

ModalInterimAvailabilityEditor.propTypes = {
  availabilityTypes: PropTypes.array.isRequired,
  availabilityLegendBlocks: PropTypes.array.isRequired,
  job: PropTypes.object,
};
