import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import { mapLegendBlock, noop, overnightAvailability, preparePermanentAvailability } from 'shared/functions';

import CardPermanentAvailability from 'containers/AvailabilityEditor/CardPermanentAvailability';
import CardInterimAvailability from 'containers/AvailabilityEditor/CardInterimAvailability';

class Availability extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {};
  availabilityTypes: any;
  availabilityLegendBlocks: any;
  static contextTypes: { settings: PropTypes.Validator<object> };
  static propTypes: {
    job: PropTypes.Validator<object>;
    workHistoryPresent: PropTypes.Validator<boolean>;
    isInterim: PropTypes.Validator<boolean>;
  };

  UNSAFE_componentWillMount() {
    this.availabilityTypes = [
      {
        event: translate('Not defined'),
        color: '#D6DFE4',
        lightenColor: '#EDEDED',
      },
      {
        event: translate('Available'),
        color: '#57AF58',
        lightenColor: '#C8E6C9',
      },
    ];
    const availabilityLegendItems = {
      leftRow: [
        {
          background: this.availabilityTypes[1].lightenColor,
          label: translate('Available'),
        },
        {
          background: this.availabilityTypes[0].lightenColor,
          label: translate('Not defined'),
        },
      ],
      rightRow: [],
    };

    this.availabilityLegendBlocks = [
      availabilityLegendItems.leftRow.map(mapLegendBlock),
      availabilityLegendItems.rightRow.map(mapLegendBlock),
    ];
    this.initAvailability();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { job } = nextProps;
    if (this.props.job !== job && job.code) {
      this.initAvailability(nextProps);
    }
  }

  initAvailability(props = this.props) {
    const { job } = props;
    if (get(job, 'permanentAvailability')) {
      this.setState({
        availabilityDays: overnightAvailability(preparePermanentAvailability(job.permanentAvailability).days),
      });
    }
  }

  renderAvailabilityCard(isInterim) {
    const { settings } = this.context;
    const { job } = this.props;

    if (isInterim) {
      return (
        <CardInterimAvailability
          availabilityTypes={this.availabilityTypes}
          availabilityLegendBlocks={this.availabilityLegendBlocks}
          settings={settings}
          profile={job}
          extended
        />
      );
    }
    return (
      <CardPermanentAvailability
        availabilityTypes={this.availabilityTypes}
        availabilityLegendBlocks={this.availabilityLegendBlocks}
        settings={settings}
        profile={job}
        extended
        savePermanentAvailability={noop}
      />
    );
  }

  render() {
    const { workHistoryPresent, isInterim } = this.props;

    if (!workHistoryPresent) {
      return null;
    }
    return (
      <div className="job-info-block" id="job-working-hours">
        <div className="job-detail-container">
          <h3>{translate(isInterim ? 'Shifts' : 'Working hours')}</h3>
          <div className="row">
            <div className="col-sm-8 col-12">{this.renderAvailabilityCard(isInterim)}</div>
            <div className="col-sm-4 col-12">{this.renderAvailabilityCard(isInterim)}</div>
          </div>
        </div>
      </div>
    );
  }
}

Availability.contextTypes = {
  settings: PropTypes.object.isRequired,
};

Availability.propTypes = {
  job: PropTypes.object.isRequired,
  workHistoryPresent: PropTypes.bool.isRequired,
  isInterim: PropTypes.bool.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default Availability;
