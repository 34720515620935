import React from 'react';

import type { JobDto } from '@eva/types/dto';

import { SkillTaxonomiesViewTreeList } from 'widgets/SkillTaxonomies/SkillTaxonomiesViewTreeList';

type RequirementsAndSkillsType = {
  job: JobDto;
  code: string;
  isSkillTaxonomyTrees: boolean;
};

export const RequirementsAndSkillsSection: React.FC<RequirementsAndSkillsType> = (props) => {
  const {
    job: { languages, qualification, degreeTypes },
    code,
    isSkillTaxonomyTrees,
  } = props;

  return (
    <div className="job-detail-container" id="job-skills">
      <h3>{translate('Requirements and skills')}</h3>
      <div className="row job-info-block requirements-and-skills-section">
        {isSkillTaxonomyTrees && <SkillTaxonomiesViewTreeList code={code} />}
        {!!languages.items.length && (
          <div className="col-sm-4 col-12 margin-bottom" id="job-languages">
            <h4>{translate('Languages')}</h4>
            <ul className="languages">
              {languages.items.map((language) => (
                <li key={language.languageId} className="margin-top">
                  <div className="flag-wrapper">
                    <span className={`flag-icon flag-icon-${language.name.split(' ')[0]} flag-icon-${language.code}`} />
                  </div>
                  <span>{language.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        {!!qualification && (
          <div className="col-sm-4 col-12 margin-bottom" id="job-education">
            <h4>{translate('Education / qualification')}</h4>
            <p>{qualification}</p>
          </div>
        )}
        {!!degreeTypes.items.length && (
          <div className="col-sm-4 col-12 margin-bottom" id="job-degreeTypes">
            <h4>{translate('Degree types')}</h4>
            <ul className="dot-list">
              {degreeTypes.items.map((degreeType) => (
                <li key={degreeType.degreeTypeId}>
                  <span>{degreeType.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
