import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import moment from 'moment';

import { renderSection } from '@eva/emf/app/shared/functions';

const currentAvailabilityLabel = (currentAvailability) => {
  if (currentAvailability === true) {
    return translate('Available');
  } else if (currentAvailability === false) {
    return translate('Not available');
  } else if (currentAvailability == null) {
    return translate('Unknown');
  }
};

// eslint-disable-next-line import/no-default-export
export default class CardAvailabilityDetails extends React.PureComponent<any, any> {
  state = {};

  render() {
    const {
      settings: {
        dateFormats: { dateFormat },
      },
    } = this.context;
    const {
      entity: { availability, noticePeriod },
    } = this.props;
    // @ts-expect-error
    const { error } = this.state;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        {renderSection(
          translate('Availability details'),
          <div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label className="control-label">{translate('Current availability')}</label>
                  <div>{currentAvailabilityLabel(availability.currentAvailability)}</div>
                </div>
              </div>
              {availability.currentAvailability === false && (
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label className="control-label">{translate('Follow up')}</label>
                    <div>
                      {availability.availabilityFollowUp &&
                        moment(availability.availabilityFollowUp).format(dateFormat)}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label className="control-label">{translate('Notice period')}</label>
                  <div>
                    {noticePeriod.fixedDate
                      ? moment(noticePeriod.fixedDate).format(dateFormat)
                      : get(noticePeriod, 'noticePeriod.name')}
                  </div>
                </div>
              </div>
            </div>
          </div>,
        )}
      </div>
    );
  }
}

// @ts-expect-error
CardAvailabilityDetails.contextTypes = {
  settings: PropTypes.object.isRequired,
};

// @ts-expect-error
CardAvailabilityDetails.propTypes = {
  entity: PropTypes.object.isRequired,
};
