import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { Field } from 'redux-form/immutable';

import { Input } from '@eva/emf/app/shared/ui/Input';
import { phoneTypesKeys } from 'shared/constants';

import SharedPhoneLink from 'components/SharedPhoneLink';

// eslint-disable-next-line import/no-default-export
export default class FormContactDetailsCore extends React.Component<any, any> {
  UNSAFE_componentWillMount() {
    const { entity } = this.props;
    this.setState({
      linkedInHard: get(entity, 'socialMediaLinks.linkedIn.hard'),
      facebookHard: get(entity, 'socialMediaLinks.facebook.hard'),
    });
  }

  renderPhoneField = (phoneTypesKey) => {
    const {
      entity: { phones },
    } = this.props;

    const phoneValue = phones[phoneTypesKey];

    return (
      <div className="col-12 col-md-6" key={phoneTypesKey}>
        <div>
          <Field
            component={Input}
            name={`phones.${phoneTypesKey}.number`}
            label={translate(`${startCase(phoneTypesKey)} Phone`)}
            prefix={phoneValue && phoneValue.duplicates && <SharedPhoneLink number={phoneValue.number} />}
          />
        </div>
      </div>
    );
  };

  renderEmailFields({ input: { name }, meta: { error } }) {
    const { _error } = (error && error.toJS ? error.toJS() : error) || {};
    return (
      <div className="row">
        <div className="col-12 col-md-6">
          <Field
            component={Input}
            name={`${name}.primary`}
            label={translate('Primary email')}
            prefix={<i className="lnr lnr-envelope" />}
          />
        </div>
        <div className="col-12 col-md-6">
          <Field
            component={Input}
            name={`${name}.secondary`}
            label={translate('Secondary email')}
            nullify
            prefix={<i className="lnr lnr-envelope" />}
          />
        </div>
        {_error && <div className="text-danger text-center">{_error}</div>}
      </div>
    );
  }

  render() {
    const { isAllowedOperation } = this.context;
    const { handleSubmit } = this.props;

    return (
      <div onSubmit={handleSubmit}>
        <h4>{translate('Contact information')}</h4>

        <div className="row phones">{phoneTypesKeys.map(this.renderPhoneField)}</div>
        <hr className="dotted" />
        <Field component={this.renderEmailFields} name="emails" />
        <hr className="dotted" />
        {isAllowedOperation('myProfile-location-view') && (
          <div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Field component={Input} name="address.street" label={translate('Street')} />
              </div>
              <div className="col-12 col-md-6">
                <Field component={Input} name="address.city" label={translate('City')} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Field component={Input} name="address.state" label={translate('Country')} />
              </div>
              <div className="col-12 col-md-6">
                <Field component={Input} name="address.postalCode" label={translate('Postal code')} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// @ts-expect-error
FormContactDetailsCore.contextTypes = {
  isAllowedOperation: PropTypes.func.isRequired,
};

// @ts-expect-error
FormContactDetailsCore.propTypes = {
  entity: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  jobEmploymentType: PropTypes.string,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  switchEditMode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};
