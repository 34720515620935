import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { logError } from 'shared/functions';

const selectionModes = {
  days: 'days',
  months: 'months',
  years: 'years',
};

class Calendar extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    selectionMode: selectionModes.days,
    yearsSelectionShift: 0,
  };
  weekdays: any;
  months: any;
  static propTypes: {
    editable: PropTypes.Validator<boolean>;
    selectedDate: PropTypes.Validator<string>;
    outputFormat: PropTypes.Validator<string>;
    selectDate: PropTypes.Validator<(...args: any[]) => any>;
    defaultDate: PropTypes.Requireable<string>;
  };

  UNSAFE_componentWillMount() {
    const { selectedDate, defaultDate, outputFormat } = this.props;

    this.weekdays = moment.weekdays().map((weekday) => weekday.substr(0, 1));

    this.weekdays.push(this.weekdays.shift());

    this.months = moment.months();
    let dateSelected;
    const requestedDate = selectedDate || defaultDate;
    if (requestedDate) {
      dateSelected = moment(requestedDate, outputFormat);
      if (!dateSelected.isValid()) {
        logError(`Calendar failed to parse date ${selectedDate} from format ${outputFormat}`);
        dateSelected = moment();
      }
    } else {
      dateSelected = moment();
    }

    this.setState({
      month: dateSelected.clone(),
      dateSelected,
      yearsShift: Array.from(Array(6).keys()),
    });
  }

  switchShift(direction) {
    const { month, yearsSelectionShift, yearsShift, selectionMode } = this.state as any;
    if (selectionMode === selectionModes.years) {
      this.setState({ yearsSelectionShift: yearsSelectionShift + direction * yearsShift.length * 2 });
    } else {
      this.setState({ month: month.add(direction, 'M') });
    }
  }

  switchSelectionMode(mode) {
    const { selectionMode } = this.state;
    this.setState({
      selectionMode: selectionModes[selectionMode === mode ? selectionModes.days : mode],
      yearsSelectionShift: 0,
    });
  }

  renderWeek(currentMonth, startDate) {
    const { outputFormat, editable } = this.props;
    const { dateSelected } = this.state as any;
    const renderDate = (showDate, key) => (
      <div key={startDate.toString()} className="day">
        <span
          style={{
            cursor: editable ? 'pointer' : 'default',
            backgroundColor: dateSelected.isSame(startDate) ? '#1088E2' : '',
            color: dateSelected.isSame(startDate) ? 'white' : '',
          }}
          onClick={() => {
            if (editable) {
              this.setState({ dateSelected: moment(key, outputFormat) });
              this.props.selectDate(key);
            }
          }}
        >
          {showDate && startDate.format('D')}
        </span>
      </div>
    );

    return (
      <div key={startDate.format()}>
        {this.weekdays.map(
          () => startDate.add(1, 'd') && renderDate(startDate.month() === currentMonth, startDate.format(outputFormat)),
        )}
      </div>
    );
  }

  renderWeeks() {
    const { month } = this.state as any;
    const weeks = [];
    const date = month.clone().startOf('month').day('Monday').add(-1, 'd');
    const currentMonth = month.month();

    let monthIndex = date.month();
    let done;
    let count = 0;

    while (!done && count < 10) {
      weeks.push(this.renderWeek(currentMonth, date));
      done = count++ > 2 && monthIndex !== date.month(); // eslint-disable-line no-plusplus
      monthIndex = date.month();
    }

    return weeks;
  }

  render() {
    const { editable } = this.props;
    const { month, selectionMode, yearsShift, yearsSelectionShift } = this.state as any;
    const currentYear = month.year() + yearsSelectionShift;
    const reversedShift = [...yearsShift].reverse();

    return (
      <div className="calendar-block">
        <div>
          <button
            type="button"
            className="btn btn-primary switch-button"
            disabled={!editable}
            onClick={() => editable && this.switchShift(-1)}
          >
            <i className="fa fa-angle-left" />
          </button>
          <button
            type="button"
            className="btn btn-primary switch-button"
            disabled={!editable}
            onClick={() => this.switchSelectionMode(selectionModes.months)}
          >
            <i className="fa fa-angle-down margin-left pull-right" />
            {month.format('MMMM')}
          </button>
          <button
            type="button"
            className="btn btn-primary switch-button"
            disabled={!editable}
            onClick={() => this.switchSelectionMode(selectionModes.years)}
          >
            <i className="fa fa-angle-down margin-left pull-right" />
            {month.format('YYYY')}
          </button>
          <button
            type="button"
            className="btn btn-primary switch-button"
            disabled={!editable}
            onClick={() => editable && this.switchShift(1)}
          >
            <i className="fa fa-angle-right" />
          </button>
        </div>
        <div className="calendarBody">
          {selectionMode === selectionModes.months &&
            this.months.map((monthName) => (
              <div
                key={monthName}
                className="selector month"
                onClick={() =>
                  this.setState({
                    selectionMode: selectionModes.days,
                    month: month.month(monthName),
                  })
                }
              >
                {monthName}
              </div>
            ))}
          {selectionMode === selectionModes.years &&
            [-1, 1].map((sorting) =>
              (sorting < 0 ? reversedShift : yearsShift).map((year) => (
                <div
                  key={`${sorting}${year}`}
                  className="selector year"
                  onClick={() =>
                    this.setState({
                      selectionMode: selectionModes.days,
                      month: month.year(currentYear + sorting + year * sorting),
                    })
                  }
                >
                  {currentYear + sorting + year * sorting}
                </div>
              )),
            )}
          {selectionMode === selectionModes.days && (
            <div>
              {this.weekdays.map((weekday, index) => (
                <div key={index} className="day-name">
                  {weekday}
                </div>
              ))}
              {this.renderWeeks()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  editable: PropTypes.bool.isRequired,
  selectedDate: PropTypes.string.isRequired,
  outputFormat: PropTypes.string.isRequired,
  selectDate: PropTypes.func.isRequired,
  defaultDate: PropTypes.string,
};

// eslint-disable-next-line import/no-default-export
export default Calendar;
