import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form/immutable';

import InstitutionTypeahead from '@eva/emf/app/shared/ui/InstitutionTypeahead';
import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';
import { Input } from '@eva/emf/app/shared/ui/Input';
import { TipTapWrapper, tipTapPluginsMode } from '@eva/emf/app/widgets/TipTap';
import { RenderSelect } from '@eva/emf/app/shared/ui/RenderSelect';
import { useGenericContext } from 'shared/hooks';

import { validateFromAndTo } from './functions';
import CalendarSelector from './components/CalendarSelector';

const validate = (immutableValues) => {
  const values = immutableValues.toJS();
  const errors = {};
  if (!Object.keys(values).length) {
    return errors;
  }
  const { institutionId, degreeName, startDate, endDate } = values;

  validateFromAndTo(errors, startDate, endDate);

  if (!institutionId && !degreeName) {
    const message = translate('School name or degree should be filled');
    // @ts-expect-error
    errors.institutionId = message;
    // @ts-expect-error
    errors.degreeName = message;
  }
  return errors;
};

const FormEducation = ({ editOptions, submitting, dirty, invalid, setItemEditMode, initialValues, handleSubmit }) => {
  const { isAllowedOperation } = useGenericContext();
  const [fromToTouched, setFromToTouched] = useState(false);

  return (
    <form className="edit-user" onSubmit={handleSubmit}>
      <hr className="dotted" />
      <div>
        <InstitutionTypeahead
          isAllowedOperation={isAllowedOperation}
          name="institutionId"
          label={translate('School name')}
        />
        <Field component={Input} name="degreeName" label={translate('Degree name')} nullify />

        <label className="control-label">{translate('from')}</label>
        <Field name="startDate" touched={fromToTouched} component={CalendarSelector} setTouched={setFromToTouched} />

        <label className="control-label">{translate('to')}</label>
        <Field name="endDate" touched={fromToTouched} component={CalendarSelector} setTouched={setFromToTouched} />

        <Field
          name="degreeType.degreeTypeId"
          component={RenderSelect}
          label={translate('Degree type')}
          options={editOptions.degreeTypes}
          valueKey="degreeTypeId"
          labelKey="name"
        />
        <Field
          component={TipTapWrapper}
          name="description"
          label={translate('Education description')}
          nullify
          pluginsMode={tipTapPluginsMode.text}
          isRichText
          isHtmlMode
        />
      </div>
      <div className="text-end">
        <button
          type="submit"
          id="submit-education"
          className="btn btn-sm btn-success"
          disabled={(dirty && invalid) || submitting}
        >
          {submitting && <SpinnerSmall />} {translate('Submit')}
        </button>
        <button
          id="cancel-edit-education"
          type="button"
          className="btn btn-sm btn-default margin-left"
          disabled={submitting}
          onClick={() => {
            const { educationId } = initialValues.toJS();
            setItemEditMode(educationId || null, false);
          }}
        >
          {translate('Cancel')}
        </button>
      </div>
    </form>
  );
};

FormEducation.propTypes = {
  initialValues: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  setItemEditMode: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default reduxForm({
  // eslint-disable-line no-class-assign
  form: 'education',
  enableReinitialize: true,
  touchOnChange: true,
  touchOnBlur: true,
  validate,
  // @ts-expect-error
})(FormEducation);
