/* eslint-disable no-restricted-properties */
import '@eva/emf/app/assets/css/flag-icon.css';
import 'whatwg-fetch';
import './style.css';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import Carousel from 'react-bootstrap/Carousel';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';

import SalaryFormatter from '@eva/emf/app/shared/ui/SalaryFormatter';
import { getServerUrl, request, requestBackend } from '@eva/emf/app/utils/request';
import {
  agencyTypes,
  candidateRegistrationWorkflowCodes,
  candidatePrefix,
  employmentTypes,
  baseEndpoint,
  screeningStatuses,
  jobDetailsForms,
} from 'shared/constants';
import {
  getCandidateTraces,
  getQueryVariables,
  isMobileMode,
  onTokenFailed,
  prepareSelector,
  setQueryVariables,
  stringifyError,
  temporaryUser,
  redirectToExternalUrl,
  toQueryString,
  windowLocationReload,
  replaceUrl,
} from 'shared/functions';
import { Spinner, SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';

import Helmet from 'containers/Helmet';
import MessengerChatHeader from 'containers/MessengerChatHeader';
import { signOut, signInSuccess } from 'containers/App/actions';
import { loadChats, loadJob, loadUserDetails, onPipelineUpdated } from 'containers/MessengerCandidate/actions';
import noImage from 'assets/images/no-company.png';

import { ExternalApplicationButton } from '../../entities/JobExternalApplications';
import { RequirementsAndSkills } from '../../widgets/RequirementsAndSkills';

import styles from './JobDetails.module.scss';
import Availability from './Availability';
import InfoAndPerks from './InfoAndPerks';
import LocationMap from './LocationMap';
import ScreeningQuestions from './ScreeningQuestions';
import { findByTerminal, showChatMessageNotification } from './functions';
import { WorkflowButton } from './WorkflowButton';

const morePhotosIndex = 4;

const eventTypes = {
  pipelineUpdated: 'candidate-pipeline-updated',
  webchatMessageSent: 'webchat-message-sent',
};

const resetApplyingState = {
  applyingStatus: false,
  actionMessage: '',
};

type JobDetailsStateType = {
  imagesMode: any;
  companyJson: { [key: string]: any };
  availabilityShifts: any[];
  availabilityDays: any[];
  workflowTransitions: any[];
  screeningForm: boolean;
  resetApplyingState?: {
    applyingStatus: boolean;
    actionMessage: string;
  };
  actionMessage?: string;
  checkingSignedIn?: boolean;
  applyError?: any;
  applyingStatus?: boolean;
  loadingTransitions?: boolean;
  error?: any;
  buttonsGlued?: boolean;
};

class JobDetails extends React.Component<any, JobDetailsStateType> {
  windowScrollHandler: (this: Window, ev: Event) => any;
  weekdays: string[];
  buttonsBlock: any;
  unmounted: any;
  state: JobDetailsStateType = {
    imagesMode: false,
    companyJson: {},
    availabilityShifts: [],
    availabilityDays: [],
    workflowTransitions: [],
    screeningForm: !!getQueryVariables()?.screeningForm,
  };

  UNSAFE_componentWillMount() {
    this.redirectToSignInOrInit();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user, userProfile, wrapped, socket, job, jobCode, dispatchLoadJob, screeningForm } = nextProps;

    const { jobportal, screeningForm: QuestionsForm } = getQueryVariables();

    if (this.props.jobCode !== nextProps.jobCode) {
      dispatchLoadJob(nextProps.jobCode);
      this.setState(resetApplyingState);
    }

    if (!this.props.user.userId && user.userId) {
      windowLocationReload();
    }

    const userProfileLoaded = !this.props.userProfile.userId && userProfile.userId;
    const jobLoaded = !this.props.job.code && job.code;
    if (wrapped && (userProfileLoaded || jobLoaded)) {
      const registrationCode = get(userProfile, 'workflowRegistration.code');
      if (registrationCode === candidateRegistrationWorkflowCodes.registered) {
        if (job.pipelines && job.pipelines.length) {
          replaceUrl(`${candidatePrefix}?job=${job.code}`);
        }
      }
    }

    if (!this.props.job.code && job.code) {
      if (job.applyStatus) {
        this.setState({
          actionMessage: this.applyStatusName(),
        });
      }
      if (jobportal) {
        this.applyFromPublic(nextProps, 'jobportal');
      }
    }

    if (!this.props.socket && socket) {
      this.onConnectSocket(nextProps);
    }

    if (this.props.jobCode !== jobCode) {
      this.loadTransitions(nextProps);
    }

    if (QuestionsForm === jobDetailsForms.screeningForm && this.props.screeningForm !== screeningForm) {
      this.setState({
        screeningForm: true,
      });
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    window.removeEventListener('scroll', this.windowScrollHandler, true);
    // @ts-expect-error
    window.jobDetails = false;
    this.onDisconnectSocket();
  }

  onPipelineUpdated = (payload) => {
    const { dispatchOnPipelineUpdated } = this.props;

    dispatchOnPipelineUpdated(payload);
  };

  onChatMessage = (payload) => {
    if (!payload?.message?.ai) {
      return;
    }

    showChatMessageNotification(payload.message);
  };

  onConnectSocket(props = this.props) {
    const { wrapped, socket, dispatchLoadChats } = props;

    if (wrapped) {
      dispatchLoadChats();
    }

    socket.on(eventTypes.pipelineUpdated, this.onPipelineUpdated);
    socket.on(eventTypes.webchatMessageSent, this.onChatMessage);
  }

  onDisconnectSocket() {
    const { socket } = this.props;

    socket.off(eventTypes.pipelineUpdated, this.onPipelineUpdated);
    socket.off(eventTypes.webchatMessageSent, this.onChatMessage);
  }

  async loadTransitions(props = this.props) {
    const { jobCode } = props;

    this.setState({
      loadingTransitions: true,
    });

    if (this.unmounted) {
      return;
    }

    try {
      const response: { workflowTransitions?: any[]; publicTransition?: any } = await requestBackend(
        `/public-jobs/${jobCode}/pipelines/edit-options`,
      );

      this.setState({
        workflowTransitions: response.workflowTransitions || [response.publicTransition],
      });
    } catch (err) {
      const errorMessage = err.status === 404 ? err.payload?.error : stringifyError(err);

      this.setState({
        error: errorMessage,
      });
    } finally {
      this.setState({
        loadingTransitions: false,
      });
    }
  }

  applyStatusName() {
    return translate('Processing');
  }

  windowScrolled() {
    if (!this.buttonsBlock) {
      return;
    }
    const { buttonsGlued } = this.state as any;
    const jobCentralPanelElement = document.getElementsByClassName('job-central-panel');
    const scrollTop =
      document.body.scrollTop ||
      document.documentElement.scrollTop ||
      (jobCentralPanelElement[0] ? jobCentralPanelElement[0].scrollTop : 0);
    const glueButtons = scrollTop > this.buttonsBlock.offsetTop;
    if (glueButtons !== buttonsGlued) {
      this.setState({ buttonsGlued: glueButtons });
    }
  }

  initContainer() {
    const { socket, jobCode, wrapped, dispatchLoadJob, dispatchLoadUserDetails, dispatchSignInSuccess } = this.props;
    const { invite, firstName } = getQueryVariables();

    if (firstName) {
      localStorage.firstName = firstName;
      setQueryVariables({
        firstName: undefined,
      });
    }

    if (invite) {
      // @ts-expect-error
      return request(`${baseEndpoint}/api/authentication/auth/login?token=${encodeURIComponent(invite)}`).then(
        (response) => {
          setQueryVariables({
            invite: undefined,
          });
          dispatchSignInSuccess(response);
          windowLocationReload();
        },
        () => onTokenFailed(`${window.location.pathname}${window.location.search}`),
      );
    }

    this.loadTransitions();

    this.weekdays = moment.weekdays();
    this.weekdays.push(this.weekdays.shift());
    dispatchLoadJob(jobCode);

    if (wrapped && localStorage.token) {
      dispatchLoadUserDetails();
    }

    if (socket) {
      this.onConnectSocket();
    }

    this.windowScrollHandler = this.windowScrolled.bind(this);
    window.addEventListener('scroll', this.windowScrollHandler, true);
    // @ts-expect-error
    window.jobDetails = true;
  }

  async redirectToSignInOrInit() {
    const { invite, jobportal } = getQueryVariables();

    if ((!invite || jobportal) && getCandidateTraces()) {
      const redirectToSignIn = () => {
        const queryParams = {
          redirect: `${window.location.pathname}${window.location.search}`,
          application: 1,
        };

        redirectToExternalUrl(`/auth/sign-in?${toQueryString(queryParams)}`);
        return;
      };

      this.setState({
        checkingSignedIn: true,
      });

      if (!localStorage.token) {
        return redirectToSignIn();
      }

      return fetch(getServerUrl('/my/candidate-profile/user'), {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.token}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          this.setState({
            checkingSignedIn: false,
          });
          return this.initContainer();
        }
        redirectToSignIn();
      });
    }

    this.initContainer();
  }

  redirectAfterApplication = () => {
    const { userDetails, jobCode } = this.props;

    if (this.unmounted) {
      return;
    }

    let postfix = '';
    if (get(userDetails, 'rw.workflow_state.code') === 'registered') {
      postfix = `?job=${jobCode}`;
    }
    replaceUrl(`${candidatePrefix}${postfix}`);
  };

  trackUsage = () => {
    const { userDetails, jobCode } = this.props;

    if (this.unmounted) {
      return;
    }

    const userId = get(userDetails, 'userId');
    const registrationCode = get(userDetails, 'rw.workflow_state.code');
    return requestBackend('/cbe/settings/track-usage', {
      method: 'POST',
      body: JSON.stringify({
        usageType: 'job-details-click',
        descriptor: {
          userId,
          registrationCode,
          jobCode,
        },
      }),
    });
  };

  showApplicationError = (err) => {
    if (this.unmounted) {
      return;
    }
    this.setState({
      applyError: stringifyError(err),
      actionMessage: '',
    });
  };

  applyOrReject({ workflowTransitionId, workflowTransitionTargetId, workflowState }) {
    const { job, jobCode, wrapped, emitSendMessage, closeJobDetails } = this.props;
    this.setState({
      applyError: '',
      actionMessage: this.applyStatusName(),
    });

    Promise.all([
      requestBackend(`/pipelines/${job.pipelines[0].pipelineId}/move`, {
        method: 'POST',
        body: JSON.stringify({
          workflowTransitionId,
          workflowTransitionTargetId,
          note: '',
        }),
      }),
      this.trackUsage(),
    ]).then(() => {
      if (wrapped || workflowState.isTerminal) {
        return this.redirectAfterApplication();
      }
      this.loadTransitions();
      this.setState({
        applyingStatus: false,
        actionMessage: '',
        workflowTransitions: [],
      });
    }, this.showApplicationError);

    if (workflowState.isTerminal) {
      if (emitSendMessage) {
        emitSendMessage(`/postjob ${jobCode}#I am no longer interested:`);
      }
      if (closeJobDetails) {
        closeJobDetails();
      }
    }
  }

  applyFromPublic(props = this.props, source = '') {
    const { job } = props;
    this.setState({
      applyError: '',
      actionMessage: this.applyStatusName(),
    });

    if (typeof this.props.onApply === 'function') {
      return Promise.all([this.props.onApply(job), this.trackUsage()])
        .then(() =>
          this.setState({
            actionMessage: '',
          }),
        )
        .catch(this.showApplicationError);
    }

    if (props.user.userId) {
      return Promise.all([
        requestBackend(`/pipelines/job-apply/${job.code}`, {
          method: 'POST',
          body: JSON.stringify({
            source,
          }),
        }),
        this.trackUsage(),
      ]).then(this.redirectAfterApplication, this.showApplicationError);
    }

    Promise.all([
      requestBackend(`/public-jobs/apply/${job.code}${window.location.search}`, {
        method: 'POST',
        body: JSON.stringify({
          source,
        }),
      }),
      this.trackUsage(),
      // @ts-expect-error
    ]).then(([{ user, token }]) => {
      redirectToExternalUrl(
        `/auth/sign-in?${toQueryString({
          ...user,
          token,
        })}`,
      );
    }, this.showApplicationError);
  }

  backToJobDetails = () => {
    const query = getQueryVariables();
    const newQuery = { ...query };

    delete newQuery.screeningForm;

    replaceUrl({
      pathname: window.location.pathname,
      query: newQuery,
    });

    this.setState({
      screeningForm: false,
    });
  };

  renderScreeningQuestions = (state = this.state, props = this.props) => {
    const { selectedPipeline } = props;
    const { screeningForm } = state;

    if (selectedPipeline && selectedPipeline.screeningQuestions.screeningStatus !== screeningStatuses.notExists) {
      return (
        <ScreeningQuestions
          pipelineId={selectedPipeline.pipelineId}
          isAllowedToAnswer={selectedPipeline.screeningQuestions.isAllowedToAnswer}
          showQuestionsForm={screeningForm}
          renderQuestionForm={this.renderQuestionForm}
          backToJobDetails={this.backToJobDetails}
        />
      );
    }
    return null;
  };

  renderQuestionForm = () => {
    setQueryVariables({
      screeningForm: jobDetailsForms.screeningForm,
    });

    this.setState({
      screeningForm: true,
    });
  };

  renderOptionsNode() {
    const {
      job: { code, pipelines, externalApplications },
      jobCode,
      emitSendMessage,
      closeJobDetails,
    } = this.props;

    const { applyingStatus, applicationError, actionMessage, workflowTransitions } = this.state as any;

    const pipeline = get(pipelines, 0);
    const positiveBlock = findByTerminal(false, workflowTransitions);
    const negativeBlock = findByTerminal(true, workflowTransitions);
    const positiveTarget =
      positiveBlock && positiveBlock.workflowTransitionTargets.find(({ workflowState }) => !workflowState.isTerminal);
    const terminalTarget =
      negativeBlock && negativeBlock.workflowTransitionTargets.find(({ workflowState }) => workflowState.isTerminal);
    const renderApplyButton = () => {
      if (actionMessage) {
        return (
          <div className="status">
            <span>
              <SpinnerSmall /> {actionMessage}, {translate('please wait...')}
            </span>
          </div>
        );
      }
      const showWorkflowButton = positiveBlock && positiveTarget;
      const workflowButtonProps = {
        disabled: applyingStatus || actionMessage,
        color: positiveBlock?.color,
        onClick: () => (pipeline ? this.applyOrReject(positiveTarget) : this.applyFromPublic()),
        buttonName: positiveBlock?.buttonName,
      };

      return (
        <div>
          <div className="apply-block">
            <div className="apply">
              {externalApplications ? (
                <ExternalApplicationButton
                  pipelineId={pipeline.pipelineId}
                  externalApplicationUrl={externalApplications.externalApplicationUrl}
                  jobExternalApplicationId={externalApplications.jobExternalApplicationId}
                />
              ) : (
                showWorkflowButton && <WorkflowButton {...workflowButtonProps} block />
              )}
            </div>
          </div>
          <div className="clear" />
          <div className="job-detail-action second-line text-nowrap">
            {!!emitSendMessage && (showWorkflowButton || terminalTarget) && (
              <div className="tell-more">
                <button
                  type="button"
                  className="btn btn-box-tool btn-sm"
                  onClick={() => {
                    emitSendMessage(
                      translate('{{ prefix }}I would like to know more about this job:', {
                        prefix: `/postjob ${jobCode}#`,
                      }),
                    );
                    closeJobDetails();
                  }}
                >
                  {translate('Tell me more')}
                </button>
              </div>
            )}
            {showWorkflowButton && externalApplications && <WorkflowButton {...workflowButtonProps} />}
            {terminalTarget && (
              <div className="reject">
                <button
                  type="button"
                  className="btn btn-box-tool btn-sm"
                  disabled={applyingStatus || actionMessage}
                  style={{
                    color: negativeBlock.color,
                  }}
                  onClick={() => this.applyOrReject(terminalTarget)}
                >
                  {negativeBlock.buttonName}
                </button>
              </div>
            )}
            {actionMessage && (
              <div className="status">
                <span className="text-muted">
                  <SpinnerSmall /> {actionMessage}, {translate('please wait...')}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    };

    if (applyingStatus) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    if (applicationError) {
      return <div className="text-danger">{applicationError}</div>;
    }
    if (!pipeline || positiveTarget || externalApplications || (externalApplications && terminalTarget)) {
      return renderApplyButton();
    }
    if (terminalTarget) {
      return (
        <div className="end-app">
          <div className="job-detail-action">
            <div className="text-center margin-bottom">
              <button
                type="button"
                className="btn btn-primary btn-sm btn-block"
                onClick={() => window.open(`${window.location.origin}${candidatePrefix}?pj=${code}`, '_blank')}
              >
                {translate('Talk to an agent')}
              </button>
            </div>
          </div>
          <div className="reject">
            <button
              type="button"
              className="btn btn-box-tool btn-sm"
              style={{
                color: negativeBlock.color,
              }}
              onClick={() => this.applyOrReject(terminalTarget)}
            >
              {negativeBlock.buttonName}
            </button>
          </div>
        </div>
      );
    }
  }

  renderSpinner() {
    return (
      <div className="text-center padding-top-wrapper">
        <Spinner /> <span>{translate('Loading Job')}</span>
      </div>
    );
  }

  renderError(anyError) {
    return (
      <div className="text-center padding-top-wrapper">
        <h3>{anyError}</h3>
      </div>
    );
  }

  render() {
    const {
      settings,
      user,
      userDetails,
      job,
      loadingJob,
      loadJobError,
      wrapped,
      dispatchSignOut,
      userProfile,
      jobCode,
    } = this.props;

    const { imagesMode, buttonsGlued, parseError, applyError, checkingSignedIn, error, screeningForm, actionMessage } =
      this.state as any;

    const anyError = loadJobError || parseError || error;

    if (anyError) {
      return this.renderError(anyError);
    }

    if (checkingSignedIn || loadingJob || !settings.logos || actionMessage) {
      return this.renderSpinner();
    }

    const { general } = settings;

    const currencyValue = get(settings, 'currency.value', '£');
    const optionsNode = this.renderOptionsNode();
    const isInterim = job.employmentType.code === employmentTypes.interimTemporary;
    const workHistoryPresent = !!get(job, isInterim ? 'interimAvailability.slots' : 'permanentAvailability.days', [])
      .length;
    const jobSalary = job.salary || {};
    const mobileMode = isMobileMode();
    const newCandidate = !user.userId || (Object.keys(userDetails).length && temporaryUser(userDetails));

    const jobDetails = () => {
      const screeningQuestions = this.renderScreeningQuestions();
      if (screeningForm && screeningQuestions) {
        return screeningQuestions;
      }
      return (
        <>
          {screeningQuestions}
          <InfoAndPerks job={job} jobSettings={settings} />

          {!!(job.images || []).length && (
            <div className="job-image-container">
              <div className={`photo-slider ${job.images.length === 1 ? 'text-center' : ''}`}>
                <div>
                  <img
                    src={job.images[0].url}
                    onClick={() => this.setState({ imagesMode: true })}
                    className="job-image"
                    alt=""
                  />
                </div>
                {[1, 2, 3].map(
                  (photoIndex) =>
                    job.images[photoIndex] && (
                      <div key={photoIndex}>
                        <img
                          onClick={() => this.setState({ imagesMode: photoIndex })}
                          src={job.images[photoIndex].url}
                          className="job-image"
                          alt=""
                        />
                      </div>
                    ),
                )}
                {job.images[morePhotosIndex] && (
                  <div>
                    <img src={job.images[morePhotosIndex].url} className="job-image" alt="" />
                  </div>
                )}
              </div>
            </div>
          )}

          {imagesMode && (
            <div className="photo-carousel">
              <span className="fa fa-times photo-carousel-close" onClick={() => this.setState({ imagesMode: false })} />
              <Carousel
                className="bs5"
                pause="hover"
                defaultActiveIndex={typeof imagesMode === 'boolean' ? 0 : imagesMode}
              >
                {job.images.map((photo) => (
                  <Carousel.Item key={photo.url}>
                    <img alt="" src={photo.url} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          )}
          <RequirementsAndSkills job={job} code={jobCode} />
          <Availability job={job} workHistoryPresent={workHistoryPresent} isInterim={isInterim} />

          <LocationMap job={job} />
        </>
      );
    };

    return (
      <div>
        {/** @ts-expect-error */}
        <Helmet candidateBranding={settings.candidateBranding} job={job} />

        {wrapped && user.userId && !mobileMode && (
          <MessengerChatHeader
            userProfile={userProfile}
            // @ts-expect-error
            userDetails={userDetails}
            newCandidate={newCandidate}
            dispatchSignOut={dispatchSignOut}
            mobileMode={mobileMode}
          />
        )}
        {job.externalApplications && (
          <h3 className={styles.externalApplicationText}>
            {translate(
              'Applications for this vacancy are only accepted through an external portal. Please submit your application directly.',
            )}
          </h3>
        )}

        {applyError && <h3 className="text-center text-danger">{applyError}</h3>}

        <div className="logo-container job-detail-container">
          <figure>
            <img className="signin-logo" src={settings.logos.mainLogo} alt="" />
          </figure>
        </div>

        <div ref={(ref) => (this.buttonsBlock = this.buttonsBlock || ref)}>
          <div className={`job-info-block ${buttonsGlued && window.top ? 'sticky-top' : ''}`} id="job-summary-info">
            <div className="job-cover-color">
              <div className="job-detail-container">
                <div className={classNames('row', styles.jobDetailsHeader)}>
                  <div className="col-sm-8 col-12">
                    <div className="media title-block">
                      {general.type === agencyTypes.agency && (
                        <div className="media-left">
                          <div className="company-img-crop">
                            <img
                              className="media-object"
                              src={get(job, 'avatar.url') || get(job, 'company.avatar.url', noImage)}
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                      <div className="media-body">
                        <h3 className="media-heading text-primary">{job.title}</h3>
                        <h4>
                          {general.type === agencyTypes.agency && (
                            <span className="margin-right">{job.company.name}</span>
                          )}
                          {(job.location || {}).city && (
                            <span id="job-location" className="margin-right">
                              <i className="lnr lnr-map-marker text-muted" />
                              &nbsp;{job.location.city}
                            </span>
                          )}
                          {jobSalary.max && (
                            <span id="job-salary" className="margin-right">
                              <SalaryFormatter currencyValue={currencyValue} salary={jobSalary} withCurrency />
                            </span>
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('col-sm-4 col-12 text-center', styles.jobDetailsOptions)}>
                    {optionsNode}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: buttonsGlued && window.top ? '115px' : '0px' }} />
        </div>
        {jobDetails()}
        <div className="job-info-block hidden-sm hidden-md hidden-lg text-center bottom-btn-block">
          {optionsNode}
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

// @ts-expect-error
JobDetails.propTypes = {
  settings: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  socket: PropTypes.object,
  job: PropTypes.object.isRequired,
  pipelines: PropTypes.array.isRequired,
  selectedPipeline: PropTypes.object,
  loadingJob: PropTypes.bool.isRequired,
  loadJobError: PropTypes.string.isRequired,
  jobCode: PropTypes.string.isRequired,
  wrapped: PropTypes.bool,
  emitSendMessage: PropTypes.func,
  closeJobDetails: PropTypes.func,
  onApply: PropTypes.func,
  dispatchLoadChats: PropTypes.func.isRequired,
  dispatchLoadJob: PropTypes.func.isRequired,
  dispatchOnPipelineUpdated: PropTypes.func.isRequired,
  dispatchLoadUserDetails: PropTypes.func.isRequired,
  dispatchSignInSuccess: PropTypes.func.isRequired,
  dispatchSignOut: PropTypes.func.isRequired,
};

const prepareGlobalSelector = (value) => prepareSelector('global', value);
const prepareMessengerSelector = (value) => prepareSelector('candidate', value);
const mapStateToProps = createStructuredSelector({
  settings: prepareGlobalSelector('settings'),
  user: prepareGlobalSelector('user'),
  userProfile: prepareGlobalSelector('userProfile'),
  userDetails: prepareMessengerSelector('userDetails'),
  loadingJob: prepareMessengerSelector('loadingJob'),
  loadJobError: prepareMessengerSelector('loadJobError'),
  job: prepareMessengerSelector('job'),
  pipelines: prepareMessengerSelector('pipelines'),
});

const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error
  dispatchLoadChats: () => dispatch(loadChats()),
  dispatchLoadJob: (code) => dispatch(loadJob(code)),
  dispatchOnPipelineUpdated: (pipeline) => dispatch(onPipelineUpdated(pipeline)),
  // @ts-expect-error
  dispatchLoadUserDetails: (...args) => dispatch(loadUserDetails(...args)),
  // @ts-expect-error
  dispatchSignInSuccess: (...args) => dispatch(signInSuccess(...args)),
  // @ts-expect-error
  dispatchSignOut: () => dispatch(signOut()),
});

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
