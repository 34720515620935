import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import 'sanitize.css/sanitize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global-fix.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
/* @deprecated TODO remove in DEV-25601 v4 icons */
/* Backward capability using icons without prefix */
import '@fortawesome/fontawesome-free/css/v4-shims.css';

import { browserHistory } from '@eva/emf/app/shared/constants';
import { TranslationWarning } from '@eva/emf/app/features/TranslationWarning';

import { WithReactQuery } from 'app/providers';
import { routes } from 'utils/routes';
import { store } from 'utils/store';
import 'utils/window';

// https://github.com/ReactTraining/react-router/issues/6789#issuecomment-502490351
export const App = () => (
  <WithReactQuery>
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <Router history={browserHistory}>{renderRoutes(routes)}</Router>
      </Provider>
    </DndProvider>
    <TranslationWarning />
  </WithReactQuery>
);
