import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form/immutable';

import { Input } from '@eva/emf/app/shared/ui/Input';

// eslint-disable-next-line import/no-default-export
export default class FormLocationCore extends React.Component<any, any> {
  render() {
    const { addressField } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-6">
            <Field component={Input} name={`${addressField}.street`} label={translate('Street')} />
          </div>
          <div className="col-6">
            <Field component={Input} name={`${addressField}.city`} label={translate('City')} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Field component={Input} name={`${addressField}.state`} label={translate('Country')} />
          </div>
          <div className="col-6">
            <Field component={Input} name={`${addressField}.postalCode`} label={translate('Postal code')} />
          </div>
        </div>
      </div>
    );
  }
}

// @ts-expect-error
FormLocationCore.propTypes = {
  meta: PropTypes.object.isRequired,
  addressField: PropTypes.string.isRequired,
};
