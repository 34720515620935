import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

class SalaryAndPeriod extends AIUIComponent {
  state = {
    amount: '',
    periods: [],
    period: '',
  };

  UNSAFE_componentWillMount() {
    const periods = [translate('hour'), translate('day'), translate('week'), translate('month'), translate('year')];
    this.setState({
      periods,
      period: periods[0],
    });
  }

  submitResult() {
    const { settings } = this.context;
    const { amount, period } = this.state;
    this.setState({ submitted: true });
    const currencyValue = get(settings, 'currency.value', '£');
    this.props.resultingContent(`${translate(currencyValue)} ${amount} ${translate('per')} ${period}`);
  }

  render() {
    const { settings } = this.context;
    const { editable } = this.props;
    // @ts-expect-error
    const { submitted, amount, periods } = this.state;
    const disabled = !editable || submitted;
    const currencyValue = get(settings, 'currency.value', '£');

    if (disabled) {
      return null;
    }

    return (
      <div className="col-sm-6 col-sm-offset-3 col-12">
        <label
          className="control-label"
          style={{
            position: 'absolute',
            left: '-3px',
            top: '12px',
            fontSize: '14pt',
          }}
        >
          {translate(currencyValue)}
        </label>
        <div className="input-group">
          <input
            type="text"
            style={{
              width: '50%',
              marginRight: '-1px',
            }}
            className="form-control"
            value={amount}
            onChange={(evt) => {
              const newAmount = evt.target.value.replace(/[^.0-9]+/, '');
              // @ts-expect-error
              if ((newAmount.match(/\./g) || []).length <= 1 && newAmount < 100000) {
                this.setState({
                  amount: newAmount,
                });
              }
            }}
          />
          <div
            className="custom-select"
            style={{
              width: '50%',
            }}
          >
            <select className="form-control" onChange={(evt) => this.setState({ period: evt.target.value })}>
              {periods.map((period) => (
                <option key={period} value={period}>
                  {translate('per')} {period}
                </option>
              ))}
            </select>
          </div>
          <span className="input-group-btn">
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                padding: '11px 15px',
                marginLeft: '-2px',
              }}
              disabled={disabled || !amount}
              onClick={() => this.submitResult()}
            >
              <i className="ll-icon ll-send" />
            </button>
          </span>
        </div>
      </div>
    );
  }
}
// @ts-expect-error
SalaryAndPeriod.contextTypes = {
  settings: PropTypes.object.isRequired,
};
// @ts-expect-error
SalaryAndPeriod.propTypes = {
  attributes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default SalaryAndPeriod;
