import React from 'react';

import signInImage from 'assets/images/signin-pic.svg';
import signUpImage from 'assets/images/signup-pic.svg';

import { mainMenuKeysCamelCased, mainMenuKeysKebabCased } from './constants';

export const renderStep = (enabled, steps, step) => {
  const lesser = step < steps;
  if (enabled) {
    return (
      <div key={step} className={`col-4 ${lesser ? 'active my-jobs' : ''}`}>
        <i
          className="fa fa-circle"
          style={
            step === 1
              ? {
                  color: '#4A7AA9',
                }
              : {}
          }
        />
        <i className="fa fa-circle" />
      </div>
    );
  }
  const stepColor = steps === 4 ? '#85B852' : '#E2574C';
  const closedStep = step === steps;
  return (
    <div
      key={step}
      className={`col-4 ended ${lesser ? 'active' : ''}`}
      style={{
        borderBottomStyle: lesser ? 'solid' : 'dashed',
        borderBottomColor: lesser ? stepColor : '',
        zIndex: lesser ? 1 : 0,
      }}
    >
      <i
        className="fa fa-circle"
        style={{
          color: lesser ? stepColor : '',
        }}
      />
      {closedStep && (
        <i
          className="ll-icon ll-close"
          style={{
            display: lesser ? 'none' : 'block',
          }}
        />
      )}
      <i
        className="fa fa-circle"
        style={{
          color: lesser ? stepColor : '',
        }}
      />
    </div>
  );
};

export const getMenuKey = () => {
  const menuKey = window.location.pathname?.replace?.('/candidate/', '');
  return mainMenuKeysCamelCased[menuKey] || menuKey;
};

export const buildPathFromMenuKey = (menuKey) => {
  const pathname = window.location.pathname;

  if (!pathname) {
    return undefined;
  }

  const menuKeyInKebabCase = mainMenuKeysKebabCased[menuKey] || menuKey;

  return pathname.split('/').slice(0, -1).join('/').concat(`/${menuKeyInKebabCase}`);
};

export const getSignInFlowConfig = (renderSignInLink, resetLocalStorageAndReload) => ({
  hasNotUser: {
    img: signInImage,
    title: translate('Welcome'),
    body: (
      <>
        <p className="intro margin-bottom">{translate('Please complete your registration process.')}</p>
        <p className="intro margin-bottom">
          {renderSignInLink}
          {translate('if you already have an account.')}
        </p>
      </>
    ),
  },
  hasNotAuthMethods: {
    img: signUpImage,
    title: translate('Registration'),
    body: (
      <>
        <p className="intro margin-bottom">
          {translate('Please complete your registration process or')}
          <br />
          <a onClick={resetLocalStorageAndReload}>{translate('restart it')}.</a>
        </p>
        <p className="intro margin-bottom">
          {renderSignInLink}
          {translate('if you already have an account.')}
        </p>
      </>
    ),
  },
  hasAuthMethods: {
    img: signUpImage,
    title: translate('Registration'),
    body: <p className="intro margin-bottom">{translate('Please complete your registration process as candidate.')}</p>,
  },
});
