import { stringifyError } from 'shared/functions';
import { removePathsFromError } from '@eva/emf/app/shared/functions';
import { PageSpinner } from '@eva/emf/app/shared/ui/Spinner';

import { SkillTaxonomyTree } from '../../../entities/SkillTaxonomies/SkillTaxonomyTree';
import { useFetchSkillTaxonomyTrees } from '../../../entities/SkillTaxonomies/hooks';

type SkillTaxonomiesViewTreeListType = {
  code: string;
};

export const SkillTaxonomiesViewTreeList: React.FC<SkillTaxonomiesViewTreeListType> = ({ code }) => {
  const { data, isLoading, isError, error } = useFetchSkillTaxonomyTrees({
    code,
  });

  if (isLoading) {
    return <PageSpinner />;
  }

  if (isError) {
    const errorWithoutPath = removePathsFromError(error);
    return <h1>{stringifyError(errorWithoutPath)}</h1>;
  }

  return (
    <>
      {data &&
        data.length &&
        data.map((node) => (
          <div className="col-sm-4 col-12 margin-bottom" key={node.skillTaxonomyId}>
            <h4>{node.name}</h4>
            <SkillTaxonomyTree node={node} />
          </div>
        ))}
    </>
  );
};
