import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PopperTooltip from '@eva/emf/app/shared/ui/Popper/PopperTooltip';
import { requestBackend } from '@eva/emf/app/utils/request';
import { processValidationErrors, stringifyError } from 'shared/functions';
import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';

import {
  getProfilesSourcesDescriptions,
  loadWorkHistory,
  updateWorkHistory,
  yearAndMonthToDate,
} from 'containers/CandidatePaneNew/functions';
import { eventTypes } from 'containers/CandidatePaneNew/constants';

import FormWorkHistory from './FormWorkHistory';

const newItemKey = 'wh-new';
const whItemKeys = ['title', 'employer', 'description'];
const renderGap = (wh, index) => (
  <div key={`gap${index}`} className="gap">
    <div>
      <p>
        <i className="fa fa-warning text-warning" /> {translate('Work gap')} ({wh.gap})
      </p>
      <hr />
    </div>
  </div>
);
const prepareValues = (item) =>
  whItemKeys.reduce(
    (prev, cur) => ({
      ...prev,
      [cur]: item[cur],
    }),
    {
      isCurrent: !!item.isCurrent,
      startDate: yearAndMonthToDate(item.startDate),
      endDate: item.isCurrent ? null : yearAndMonthToDate(item.endDate),
      location: {
        street: item.location?.street || null,
        city: item.location?.city || null,
        state: item.location?.state || null,
        postalCode: item.location?.postalCode || null,
      },
    },
  );

class CardWorkHistory extends React.PureComponent<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    editModeRows: {},
    deleteModeRows: {},
    rowsErrors: {},
    profilesSourcesDescriptions: getProfilesSourcesDescriptions(),
    isCurrents: {},
  };
  static propTypes: {
    entity: PropTypes.Validator<object>;
    updateEntity: PropTypes.Validator<(...args: any[]) => any>;
    open: PropTypes.Requireable<boolean>;
  };
  private unmounted: boolean;

  UNSAFE_componentWillMount() {
    const { entity, open } = this.props;
    // @ts-expect-error
    this.loadWorkHistory(entity.workHistory, open);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity } = nextProps;

    if (this.props.entity !== entity) {
      this.loadWorkHistory(entity.workHistory);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  setItemEditMode = (workHistoryId, editMode) => {
    const { editModeRows } = this.state;

    if (workHistoryId === null) {
      return this.setState({
        newWorkHistoryItem: null,
      });
    }
    this.setState({
      editModeRows: {
        ...editModeRows,
        [workHistoryId]: editMode,
      },
    });
  };

  loadWorkHistory(workHistory) {
    this.setState(loadWorkHistory(workHistory, {}));
  }

  saveWorkHistoryItem = async (preValues) => {
    const { updateEntity } = this.props;

    const { workHistoryId, ...workHistoryItem } = preValues.toJS();

    this.setState({
      savingProfile: true,
    });

    return await requestBackend(`/my/candidate-profile/work-history/${workHistoryId || ''}`, {
      method: workHistoryId ? 'PUT' : 'POST',
      body: JSON.stringify(prepareValues(workHistoryItem)),
    }).then(
      (response) => {
        if (this.unmounted) {
          return;
        }
        const {
          entity: { workHistory },
        } = this.props;
        this.setItemEditMode(workHistoryId || null, false);
        const eventType = workHistoryId ? eventTypes.workHistoryUpdated : eventTypes.workHistoryCreated;
        updateEntity({
          workHistory: updateWorkHistory(workHistory, eventType, response),
        });
      },
      (err) => processValidationErrors(workHistoryItem)(err),
    );
  };

  async deleteWorkHistoryItem(workHistoryId: string) {
    const { updateEntity } = this.props;
    const { deleteModeRows, rowsErrors } = this.state;

    if (this.unmounted) return;

    this.setState({
      deleteModeRows: {
        ...deleteModeRows,
        [workHistoryId]: true,
      },
      rowsErrors: {
        ...rowsErrors,
        [workHistoryId]: '',
      },
    });

    try {
      await requestBackend(`/my/candidate-profile/work-history/${workHistoryId}`, {
        method: 'DELETE',
      });

      const {
        entity: { workHistory },
      } = this.props;

      updateEntity({
        workHistory: updateWorkHistory(workHistory, eventTypes.workHistoryDeleted, {
          workHistoryId,
        }),
      });
    } catch (err) {
      this.setState({
        rowsErrors: {
          ...rowsErrors,
          [workHistoryId]: stringifyError(err),
        },
      });
    } finally {
      this.setState({
        deleteModeRows: {
          ...deleteModeRows,
          [workHistoryId]: false,
        },
      });
    }
  }

  updateIsCurrent = (formName, isCurrent) => {
    const { isCurrents } = this.state;
    this.setState({
      isCurrents: {
        ...isCurrents,
        [formName]: isCurrent,
      },
    });
  };

  renderNewItem = () => {
    const { newWorkHistoryItem, isCurrents } = this.state as any;
    return newWorkHistoryItem ? (
      <div key={newItemKey}>
        <div>
          <FormWorkHistory
            form={newItemKey}
            initialValues={newWorkHistoryItem}
            isCurrent={isCurrents[newItemKey]}
            onSubmit={this.saveWorkHistoryItem}
            setItemEditMode={this.setItemEditMode}
            updateIsCurrent={this.updateIsCurrent}
          />
        </div>
      </div>
    ) : null;
  };

  renderItem(wh, index) {
    const { isAllowedOperation } = this.context;
    const { editModeRows, deleteModeRows, rowsErrors, isCurrents } = this.state;

    const description = wh.description || '';

    if (editModeRows[wh.workHistoryId]) {
      const key = `wh-${wh.workHistoryId}`;
      return (
        <div key={key}>
          <div>
            <FormWorkHistory
              form={key}
              initialValues={wh.initialValues}
              isCurrent={isCurrents[key]}
              onSubmit={this.saveWorkHistoryItem}
              setItemEditMode={this.setItemEditMode}
              updateIsCurrent={this.updateIsCurrent}
            />
          </div>
        </div>
      );
    }

    return (
      <div key={`work-history-${index}`} className="wh-item">
        {rowsErrors[wh.workHistoryId] && <div className="text-danger">{rowsErrors[wh.workHistoryId]}</div>}
        <div>
          <div className="pull-right text-nowrap">
            {isAllowedOperation('myProfile-workHistory-update') && (
              <div>
                <PopperTooltip placement="top" overlay={translate('Edit')}>
                  <button
                    id="edit-work-history"
                    type="button"
                    className="btn-box-tool btn btn-sm pencil-edit-btn no-padding"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      this.setItemEditMode(wh.workHistoryId, true);
                    }}
                  >
                    <i className="lnr lnr-pencil" />
                  </button>
                </PopperTooltip>
                {isAllowedOperation('myProfile-workHistory-delete') && (
                  <PopperTooltip placement="top" overlay={translate('Delete')}>
                    <button
                      type="button"
                      className="btn-box-tool btn btn-sm margin-left no-padding"
                      onClick={() => this.deleteWorkHistoryItem(wh.workHistoryId)}
                    >
                      {deleteModeRows[wh.workHistoryId] && <SpinnerSmall />}
                      {!deleteModeRows[wh.workHistoryId] && <i className="lnr lnr-trash text-danger" />}
                    </button>
                  </PopperTooltip>
                )}
              </div>
            )}
          </div>
          <div>
            <p className="text-black">
              <span>{wh.title}</span>
              {wh.employer && wh.title && (
                <span className="margin-left margin-right fa fa-circle text-muted small-dots" />
              )}
              <span>{wh.employer}</span>
            </p>
            <p className="margin-top">
              <i className="lnr lnr-calendar-full text-muted" />
              <span className="text-black margin-right"> {wh.period.label}</span>
              {wh.period.diff && <span>({wh.period.diff})</span>}
            </p>
            {wh.location && wh.location.displayAddress && (
              <div className="margin-top">
                {wh.location.hasError && <i className="fa fa-warning text-danger margin-right" />}
                {!wh.location.hasError && <i className="fa fa-map-marker text-muted margin-right" />}
                {wh.location.displayAddress}
              </div>
            )}
          </div>
          <div className="clearfix" />
        </div>
        <div className="row margin-bottom">
          <div className="col-12 margin-min-vertical list-format">
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
        <div className="clearfix" />
      </div>
    );
  }

  render() {
    const { isAllowedOperation } = this.context;
    const {
      entity: { workHistory },
    } = this.props;
    // @ts-expect-error
    const { currentHistory, outdated, saving, error } = this.state;

    if (saving) {
      return (
        <div>
          <p>{translate('Saving work history')}...</p>
        </div>
      );
    } else if ('plainText' in (workHistory || {})) {
      return <div className={outdated ? 'not-approved' : ''}>{workHistory.plainText}</div>;
    }

    const header = (
      <h3 onClick={(evt) => evt.stopPropagation()} className="text-primary">
        {translate('Permanent Work History')}
        {isAllowedOperation('myProfile-workHistory-create') && (
          <span className="pull-right">
            <PopperTooltip placement="top" overlay={translate('Add')}>
              <button
                type="button"
                id="add-permanent-work-history-item"
                className="btn btn-box-tool btn-sm no-padding text-success check-save-btn"
                onClick={(evt) => {
                  evt.stopPropagation();
                  this.setState({
                    newWorkHistoryItem: {
                      startDate: {},
                      endDate: {},
                    },
                  });
                }}
              >
                <i className="lnr lnr-plus-circle text-success" />
              </button>
            </PopperTooltip>
          </span>
        )}
      </h3>
    );

    return (
      <div id="permanent-work-history-panel" className={`section ${outdated ? 'not-approved' : ''}`}>
        {error && <p className="text-danger">{error}</p>}
        <div className="panel-header">{header}</div>
        {this.renderNewItem()}
        {!currentHistory.length && <p>{translate('No work history recorded')}</p>}
        {currentHistory.map((wh, index) => (wh.gap ? renderGap(wh, index) : this.renderItem(wh, index)))}
      </div>
    );
  }
}

// @ts-expect-error
CardWorkHistory.contextTypes = {
  isAllowedOperation: PropTypes.func.isRequired,
};

CardWorkHistory.propTypes = {
  entity: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

// eslint-disable-next-line import/no-default-export
export default connect()(CardWorkHistory);
