import { memo, useCallback } from 'react';
import classnames from 'classnames';

import { useGenericContext } from 'shared/hooks';
import type { JobDto } from '@eva/types/dto';

import { SkillTaxonomyPreview } from '../../../../entities/SkillTaxonomies/SkillTaxonomyPreview';

import { JobDates } from './JobDates';

import './RecommendedJobItem.scss';

export interface RecommendedJobItemProps {
  job: JobDto;
  isActive?: boolean;
  onSelect?: (job: JobDto) => void;
}

const RecommendedJobItem = ({ job, isActive, onSelect }: RecommendedJobItemProps) => {
  const {
    settings: {
      dateFormats: { dateFormat },
    },
  } = useGenericContext();

  const classNames = classnames(['media', 'job-item', 'recommended-job-item', isActive ? 'active' : undefined]);

  const onClick = useCallback(() => {
    onSelect(job);
  }, [job, onSelect]);

  const jobAvatarUrl = job?.avatar?.url || job?.company?.avatar?.url || 'https://cdn2.eva.ai/no-company.png';

  const skillTaxonomies = job.skillTaxonomies.filter((skillTaxonomy) => skillTaxonomy.skills.length !== 0);

  return (
    <div key={job.jobId} role="button" className={classNames} onClick={onClick}>
      <div className="media-left">
        <div className="company-img-crop">
          <img className="media-object" role="presentation" alt={translate('Job logo')} src={jobAvatarUrl} />
        </div>
      </div>
      <div className="media-body">
        <div className="text-start">
          <h3>{job.title}</h3>
          {!job.discreteMode && <div className="job-company margin-top">{job.company.name}</div>}

          <JobDates startDate={job.startDate} endDate={job.endDate} dateFormat={dateFormat} />
          {<SkillTaxonomyPreview skillTaxonomies={skillTaxonomies} />}
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default memo(RecommendedJobItem);
