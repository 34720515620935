import PropTypes from 'prop-types';
import React from 'react';
import kebabCase from 'lodash/kebabCase';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';

import SalaryFormatter from '@eva/emf/app/shared/ui/SalaryFormatter';
import { salaryRateLabel } from 'shared/functions';

class CardLookingFor extends React.PureComponent<any, any> {
  state = {
    showMore: {},
  };

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  render() {
    const { settings } = this.context;
    const {
      jobEmploymentType,
      entity: { desiredPositions, employmentTypes, desiredSalary, workLocationPreference },
    } = this.props;

    const currencyValue = get(settings, 'currency.value', '£');

    return (
      <div className="section">
        <h4 className="text-primary">{translate('Looking for')}</h4>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="control-label">{translate('Desired positions')}</label>
              <div>{desiredPositions.description}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="control-label">{translate('Preferred employment')}</label>
              <div>{employmentTypes.items.map(({ title }) => title).join(', ')}</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="control-label">
                {translate('Min {{salaryLabel}}', {
                  salaryLabel: salaryRateLabel(jobEmploymentType),
                })}
              </label>
              <div>
                <SalaryFormatter currencyValue={currencyValue} salary={desiredSalary.salary} withCurrency />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <span className="control-label">{translate('Work Location Preference')}</span>
              <div>
                {workLocationPreference.workLocationPreferenceTypes
                  .map((workLocationPreferenceType) => translate(upperFirst(kebabCase(workLocationPreferenceType))))
                  .join(', ')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// @ts-expect-error
CardLookingFor.contextTypes = {
  settings: PropTypes.object.isRequired,
};
// @ts-expect-error
CardLookingFor.propTypes = {
  entity: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
  jobEmploymentType: PropTypes.string,
};

// eslint-disable-next-line import/no-default-export
export default CardLookingFor;
