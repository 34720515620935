import React from 'react';
import { createRoot } from 'react-dom/client';

import { appTypes } from '@eva/emf/app/shared/constants';
import { registerAnalyticsSession } from '@eva/emf/app/shared/functions-ts';
import { sentry } from '@eva/emf/app/utils/tracing';

import { App } from './app';

registerAnalyticsSession(appTypes.candidate);

window.React = React;

sentry.init();

const MOUNT_NODE = document.getElementById('root');
const root = createRoot(MOUNT_NODE);

root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
