import PropTypes from 'prop-types';
import React from 'react';
import set from 'lodash/set';
import { reduxForm, Field } from 'redux-form/immutable';

import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import { linkifyError, minLength } from 'shared/functions';
import { requiredIfChanged } from '@eva/emf/app/shared/functions';
import { Input } from '@eva/emf/app/shared/ui/Input';
import { RenderSelect } from '@eva/emf/app/shared/ui/RenderSelect';
import { DatePicker } from '@eva/emf/app/shared/ui/DatePicker/DatePicker';

import FormContactDetailsCore from './FormContactDetailsCore';
import FormEmploymentCore from './FormEmploymentCore';

const ninPattern =
  /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/; // eslint-disable-line max-len
const minLength2 = minLength(2);
const existsFieldOptions = [
  {
    label: 'Select',
    value: 'null',
  },
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

const requiredIfChangedFirstName = [requiredIfChanged('name.firstName'), minLength2];
const requiredIfChangedLastName = [requiredIfChanged('name.lastName'), minLength2];

const validate = (immutableValues) => {
  const values = immutableValues.toJS();
  const errors = {};
  if (!Object.keys(values).length) {
    return errors;
  }
  if (values.nationalInsuranceNumber.value) {
    let error;
    if (values.nationalInsuranceNumber.value.length < 9) {
      error = translate('Value is too short');
    } else if (values.nationalInsuranceNumber.value.length > 9) {
      error = translate('Value is too long');
    } else if (!ninPattern.test(values.nationalInsuranceNumber.value)) {
      error = 'NI Number is not valid';
    }
    if (error) {
      set(errors, 'nationalInsuranceNumber.value', error);
    }
  }
  return errors;
};

class FormGeneralInformation extends React.Component<any, any> {
  renderNationalInsuranceNumberField = () => (
    <div className="col-12 col-md-6">
      <div>
        <Field component={Input} name="nationalInsuranceNumber.value" label="NI Number" nullify />
      </div>
    </div>
  );

  renderExistsField = (fieldName, label) => (
    <div className="col-12 col-md-6">
      <div>
        <Field
          component={RenderSelect}
          name={`${fieldName}.exists`}
          label={label}
          valueKey="value"
          options={existsFieldOptions}
        />
      </div>
    </div>
  );

  render() {
    const {
      settings: {
        features: {
          ui: {
            candidateProfile: { nationalInsuranceNumber, drivingLicense, rightToWork },
          },
        },
      },
    } = this.context;
    const {
      entity: { userId },
      editOptions,
      submitting,
      handleSubmit,
      error,
    } = this.props;

    return (
      <div onSubmit={handleSubmit}>
        <h4>{translate('Personal information')}</h4>
        <div className="row">
          <div className="col-2 name-title">
            <Field component={Input} name="name.title" label={translate('Title')} />
          </div>
          <div className="col-10">
            <div className="row group-fields">
              <div className="col-4 first-name">
                <Field
                  component={Input}
                  name="name.firstName"
                  label={translate('Full name')}
                  placeholder={translate('First name')}
                  validate={requiredIfChangedFirstName}
                />
              </div>
              <div className="col-4 no-padding middle-name">
                <Field component={Input} name="name.middleName" placeholder={translate('Middle name')} nullify />
              </div>
              <div className="col-4 last-name">
                <Field
                  component={Input}
                  name="name.lastName"
                  placeholder={translate('Last name')}
                  validate={requiredIfChangedLastName}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="control-label">{translate('Date of Birth')}</label>
              <div>
                <Field component={DatePicker} name="dateOfBirth.dateOfBirth" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <Field
              name="nationality.nationalityId"
              component={RenderSelect}
              label={translate('Nationality')}
              options={editOptions.nationalities}
              valueKey="nationalityId"
              labelKey="name"
            />
          </div>
        </div>
        <div className="row ni-number">
          {nationalInsuranceNumber && this.renderNationalInsuranceNumberField()}
          {drivingLicense && this.renderExistsField('drivingLicense', translate('Driving license'))}
        </div>
        <div className="row">{rightToWork && this.renderExistsField('rightToWork', translate('Right to work'))}</div>

        {!userId && (
          <div>
            <FormEmploymentCore {...this.props} />
            <FormContactDetailsCore {...this.props} />
          </div>
        )}
        <div>
          {error && <div className="alert-danger alert">{linkifyError(error)}</div>}
          <div className="text-center">{submitting && <Spinner />}</div>
        </div>
      </div>
    );
  }
}

// @ts-expect-error
FormGeneralInformation.contextTypes = {
  settings: PropTypes.object.isRequired,
};

// @ts-expect-error
FormGeneralInformation.propTypes = {
  entity: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  dropzone: PropTypes.object,
  error: PropTypes.string,
  updateEntity: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default reduxForm({
  enableReinitialize: true,
  validate,
  // @ts-expect-error
})(FormGeneralInformation);
