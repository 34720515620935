import PropTypes from 'prop-types';
import React from 'react';
import throttle from 'lodash/throttle';
import Textarea from 'react-textarea-autosize';

import PopperTooltip from '@eva/emf/app/shared/ui/Popper/PopperTooltip';
import { typingDelay } from 'shared/constants';
import { useGenericContext } from 'shared/hooks';

import AttachedFile from './AttachedFile';

const emitTyping = (socket, chat) => () =>
  socket.emit('typing', {
    conversationId: chat.conversationId,
  });

function ContentEditor(props) {
  const {
    chat,
    mobileMode,
    textareaFocused,
    newCandidate,
    file,
    uploadingFile,
    awaitingAI,
    delayScrollDown,
    sendMessage,
    setTopicsMode,
    content,
    setContent,
    setUploadButtonRef,
    fileUrl,
    progress,
    resetFile,
  } = props;

  const { socket } = useGenericContext();

  const textareaRef = React.useRef();

  const handleContentKeyPress = React.useCallback(
    (evt) => {
      if (evt.key === 'Enter') {
        if (['ctrlKey', 'shiftKey'].some((item) => evt[item])) {
          const target = evt.target;
          if (['Start', 'End'].every((item) => typeof target[`selection${item}`] === 'number')) {
            const start = target.selectionStart;
            const newValue = `${target.value.slice(0, start)}\n${target.value.slice(evt.target.selectionEnd)}`;
            setContent(newValue);
            target.selectionStart = target.selectionEnd = start + 1;
            delayScrollDown();
          }
          evt.preventDefault();
          return false;
        }
        if (!awaitingAI) {
          sendMessage(evt);
        }
      }
    },
    [awaitingAI, delayScrollDown, sendMessage, setContent],
  );

  const { current: throttledTyping } = React.useRef(
    throttle(emitTyping(socket, chat), typingDelay, {
      leading: true,
      trailing: false,
    }),
  );

  const handleContentChange = React.useCallback(
    (evt) => {
      throttledTyping();
      setContent(evt.target.value);
    },
    [setContent, throttledTyping],
  );

  const orPostfix = mobileMode && !textareaFocused && !newCandidate ? ' or' : '...';

  return (
    <div>
      <div className="input-group flex-nowrap">
        <div className="input-group-btn">
          <PopperTooltip placement="top" overlay={translate('Upload file')}>
            <button ref={setUploadButtonRef} className="btn-attachment" type="button">
              <i className="ll-icon ll-paperclip attachment" />
            </button>
          </PopperTooltip>
        </div>

        <AttachedFile
          // @ts-expect-error
          file={file}
          fileUrl={fileUrl}
          progress={progress}
          resetFile={resetFile}
          sendMessage={sendMessage}
        />

        {!(file || uploadingFile) && (
          <Textarea
            className="messenger-text"
            name="message"
            autoComplete="off"
            placeholder={`${translate('Type your message')}${orPostfix}`}
            ref={(ref) => {
              if (ref && textareaRef.current !== ref) {
                // @ts-expect-error
                textareaRef.current = ref;
                ref.focus();
              }
            }}
            value={content}
            minRows={1}
            maxRows={5}
            onChange={handleContentChange}
            onHeightChange={delayScrollDown}
            onKeyPress={handleContentKeyPress}
          />
        )}

        {!newCandidate && mobileMode && !content && !file && !uploadingFile && (
          <button
            type="button"
            onClick={() => setTopicsMode(true, delayScrollDown)}
            className="btn btn-tool-box choose-topic"
          >
            choose a topic
          </button>
        )}

        {!(file || uploadingFile) && content && (
          <span className="input-group-btn">
            <button
              className="btn btn-sm btn-tool-box btn-send"
              type="submit"
              disabled={awaitingAI || (!content && !file)}
              // @ts-expect-error
              onClick={() => textareaRef.current.focus()}
            >
              <i className="ll-icon ll-send" />
            </button>
          </span>
        )}
      </div>
    </div>
  );
}

ContentEditor.propTypes = {
  chat: PropTypes.object.isRequired,
  sendDisabled: PropTypes.bool,
  mobileMode: PropTypes.bool,
  textareaFocused: PropTypes.bool,
  newCandidate: PropTypes.bool,
  content: PropTypes.string,
  file: PropTypes.object,
  uploadingFile: PropTypes.bool,
  awaitingAI: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  sendModes: PropTypes.object,
  sendMode: PropTypes.object,
  delayScrollDown: PropTypes.func.isRequired,
  setTopicsMode: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  setUploadButtonRef: PropTypes.func.isRequired,
  fileUrl: PropTypes.string,
  progress: PropTypes.number,
  resetFile: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default ContentEditor;
