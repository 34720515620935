import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import compact from 'lodash/fp/compact';
import keys from 'lodash/fp/keys';
import isEmpty from 'lodash/fp/isEmpty';
import difference from 'lodash/fp/difference';
import { createSelector } from 'reselect';

import { prepareSelector } from '@eva/emf/app/shared/functions';

import { getConversationId } from '../../shared/functions';

const selectUser = prepareSelector('global', 'user');
const selectPipelines = prepareSelector('candidate', 'pipelines');
const selectChats = prepareSelector('candidate', 'chats');

const selectPipelineIds = createSelector(
  selectPipelines,
  pipe(
    map((pipeline: AnyObject) => parseInt(pipeline.pipelineId as string)),
    compact,
  ),
);

const selectChatIds = createSelector(selectChats, pipe(keys, compact, map(parseInt)));

export const withBsNotify = (validatorFn) => (payload) => {
  const appState = window.store.getState();

  const context = {
    currentUser: selectUser(appState),
    pipelineIds: selectPipelineIds(appState),
    chatIds: selectChatIds(appState),
  };

  const error = validatorFn(context, payload);

  // TODO review should be enable back in DEV-27457
  // if (error && window.bsNotify) {
  //   window.bsNotify(error, {
  //     eventData: payload,
  //     context,
  //   });
  // }

  return error;
};

export const validateCandidateUpdatedEvent = withBsNotify((context: any, payload: any) => {
  const { userId: updatedCandidateId } = payload;
  const { currentUser: { userId: currentUserId = undefined } = {} } = context;

  if (!updatedCandidateId) {
    return 'updatedCandidateId is not provided';
  }

  if (currentUserId !== updatedCandidateId) {
    return 'Current user ID and ID of the updated candidate do not match';
  }
});

export const validateNewMessageEvent = withBsNotify((context: any, payload: any) => {
  const conversationId = getConversationId(payload);
  const { chatIds } = context;

  if (!conversationId) {
    return 'conversationId is not provided';
  }

  if (!isEmpty(chatIds) && !chatIds.includes(conversationId)) {
    return 'Conversation message does not belong to current user';
  }
});

export const validatePipelineUpdatedEvent = withBsNotify((context: any, payload: any) => {
  const { pipelineId, candidate } = payload;
  const { currentUser } = context;

  if (!pipelineId) {
    return 'pipelineId is not provided';
  }

  if (!candidate || !candidate.userId) {
    return 'candidateId is not provided';
  }

  if (candidate.userId !== currentUser.userId) {
    return 'Updated pipeline does not belong to the current candidate';
  }
});

export const validateCandidateRegistrationCompletedEvent = withBsNotify((context: any, payload: any) => {
  const { candidateId, conversationId } = payload;
  const { chatIds } = context;

  if (!candidateId) {
    return 'candidateId is not provided';
  }

  if (!conversationId) {
    return 'conversationId is not provided';
  }

  if (!isEmpty(chatIds) && !chatIds.includes(conversationId)) {
    return 'Conversation message does not belong to the current user';
  }
});

export const validateConversationWorkflowStateChangedEvent = withBsNotify((context: any, payload: any) => {
  const { entityId: conversationId } = payload;
  const { chatIds } = context;

  if (!conversationId) {
    return 'conversationId is not provided';
  }

  if (!isEmpty(chatIds) && !chatIds.includes(conversationId)) {
    return 'Conversation does not belong to current user';
  }
});

export const validateOmnichatUpdatedEvent = withBsNotify((context: any, payload: any) => {
  const { omnichat } = payload;
  const { chatIds } = context;

  if (!omnichat || !omnichat.omnichatId) {
    return 'omnichatId is not provided';
  }

  if (!isEmpty(chatIds) && !chatIds.includes(omnichat.omnichatId)) {
    return 'Updated omnichat does not belong to current user';
  }
});

export const validateChangeStatusEvent = withBsNotify((context: any, payload: any) => {
  const { rooms } = payload;
  const { chatIds } = context;

  if (!isEmpty(rooms) && !isEmpty(chatIds) && difference(chatIds, rooms)) {
    return 'Some rooms not in user`s chats list';
  }
});

export const withValidation = (validatorFn: any, wrappedFn: any) => (payload: any) => {
  if (validatorFn(payload)) {
    return;
  }
  wrappedFn(payload);
};
